import type { HelpDeskConfigUpdateSchema } from 'lib/models/help_desk';
import type { Nullish } from 'lib/models/primatives';
import type { CnslTicketTypeType, TicketProviderType } from 'lib/prisma/enums';
import type { HelpDeskConfig } from 'lib/prisma/types';
import { create } from 'zustand';

type CurrentConfig = Omit<HelpDeskConfig, 'id' | 'orgId' | 'workspaceId'> | undefined;

interface Store {
    provider: TicketProviderType | undefined;
    namespace: Nullish<string>;
    ticketSubType: Nullish<string>;
    cnslTicketType: CnslTicketTypeType | undefined;
    currentConfig: CurrentConfig;
    setCurrent: (config: CurrentConfig) => void;
    setProvider: (provider: TicketProviderType | undefined) => void;
    setNamespace: (namespace: Nullish<string>) => void;
    setTicketSubType: (ticketSubType: Nullish<string>) => void;
    setCnslTicketType: (cnslTicketType: CnslTicketTypeType) => void;
    isDirty: () => boolean;
    payload: () => HelpDeskConfigUpdateSchema | undefined;
    reset: () => void;
}

export const useHelpDeskStore = create<Store>((set, get) => ({
    provider: undefined,
    namespace: undefined,
    ticketSubType: undefined,
    cnslTicketType: undefined,
    currentConfig: undefined,
    setCurrent: (config: CurrentConfig) =>
        set({
            provider: config?.provider ?? undefined,
            namespace: config?.namespace,
            ticketSubType: config?.ticketSubType,
            cnslTicketType: config?.cnslTicketType,
            currentConfig: config,
        }),
    // setting provider also clears the namespace and ticketSubType
    setProvider: (provider: TicketProviderType | undefined) =>
        set({
            provider,
            namespace: undefined,
            ticketSubType: undefined,
        }),
    setNamespace: (namespace: Nullish<string>) => set({ namespace }),
    setTicketSubType: (ticketSubType: Nullish<string>) => set({ ticketSubType }),
    setCnslTicketType: (cnslTicketType: CnslTicketTypeType) => set({ cnslTicketType }),
    isDirty: () => {
        const { currentConfig, provider, namespace, ticketSubType } = get();
        return (
            currentConfig?.provider !== provider ||
            currentConfig?.namespace !== namespace ||
            currentConfig?.ticketSubType !== ticketSubType
        );
    },
    payload: () => {
        const { provider, namespace, ticketSubType, cnslTicketType } = get();
        if (provider && namespace && cnslTicketType) {
            return {
                provider,
                namespace,
                cnslTicketType,
                ticketSubType,
            };
        }
        return undefined;
    },
    reset: () =>
        set({
            provider: undefined,
            namespace: undefined,
            ticketSubType: undefined,
            currentConfig: undefined,
            cnslTicketType: undefined,
        }),
}));
