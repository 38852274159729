import { Button } from '@/components/ui/button/button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Label } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { SelectAsync } from '@/components/ui/select';
import { Select } from '@/components/ui/select';
import { UserResLinkOption, UserResLinkValueContainer } from '@/components/ui/select/UserResLinkComponents';
import { useIntegrationSettings } from '@/hooks/queries/useIntegrationSettings';
import { useMemberAdd } from '@/hooks/queries/useMembership';
import { useWorkspaces } from '@/hooks/queries/useWorkspaces';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { baseFetch } from '@/lib/baseFetch';
import { getHeaders } from '@/lib/getHeaders';
import { FlagKey } from 'lib/flags/keys';
import { IntegrationId } from 'lib/integration';
import type { GetAvailibleMembersResponse } from 'lib/models/members';
import type { CnslUser } from 'lib/prisma/types';
import qs from 'qs';
import { useEffect, useState } from 'react';

interface AddMemberModalProps {
    isOpen: boolean;
    onClose: () => void;
    workspaceId?: string;
}

export function AddMemberModal({ isOpen, onClose, workspaceId }: AddMemberModalProps) {
    const [selectedUsers, setSelectedUsers] = useState<CnslUser[]>([]);
    const [email, setEmail] = useState('');
    const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<string | undefined>(workspaceId);
    const { data: settingsStatusData } = useIntegrationSettings();
    const { data: workspacesRes } = useWorkspaces();
    const workspaces = !workspacesRes || 'error' in workspacesRes ? [] : workspacesRes;
    const addMembers = useMemberAdd();
    const isWorkspacesEnabled = useFeatureFlag(FlagKey.Workspaces);

    const isOktaConnected = settingsStatusData?.status[IntegrationId.Okta]?.enabled;

    useEffect(() => {
        setSelectedWorkspaceId(workspaceId);
    }, [workspaceId]);

    const workspaceOptions =
        workspaces.map(workspace => ({
            label: workspace.name,
            value: workspace.id,
        })) || [];

    const selectedWorkspaceName = workspaces.find(w => w.id === selectedWorkspaceId)?.name;

    return (
        <Dialog open={isOpen} onOpenChange={open => !open && onClose()}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        {selectedWorkspaceName
                            ? `Add Member${isOktaConnected ? 's' : ''} to ${selectedWorkspaceName} Workspace`
                            : `Add Member${isOktaConnected ? 's' : ''}`}
                    </DialogTitle>
                </DialogHeader>
                <div className="p-lg pb-xl space-y-3">
                    <>
                        <Label className="text-grey font-semibold">Users</Label>
                        {isOktaConnected ? (
                            <SelectAsync
                                isMulti
                                closeMenuOnSelect={false}
                                components={{
                                    Option: UserResLinkOption,
                                    ValueContainer: UserResLinkValueContainer,
                                    MultiValue: () => null,
                                }}
                                loadOptions={async (search, _options, additional) => {
                                    const headers = getHeaders();

                                    const res = await baseFetch<GetAvailibleMembersResponse>(
                                        `/api/v1/members/available?${qs.stringify({ search, cursor: additional?.cursor })}`,
                                        { headers },
                                    );

                                    return {
                                        options: res.items.map(value => ({
                                            label: value.displayName,
                                            avatar: value.avatar,
                                            value,
                                            email: value.email,
                                        })),
                                        hasMore: !!res.pagination.nextCursor,
                                        additional: {
                                            cursor: res.pagination.nextCursor,
                                        },
                                    };
                                }}
                                onChange={i => setSelectedUsers(i.map(u => u.value))}
                            />
                        ) : (
                            <Input
                                type="email"
                                className="w-full mt-[-4px]"
                                placeholder="Enter an email address"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        )}
                        {isWorkspacesEnabled && !workspaceId && (
                            <div className="mt-3">
                                <Label htmlFor="workspace-select" className="text-grey font-semibold mb-1 block">
                                    Workspace
                                </Label>
                                <Select
                                    id="workspace-select"
                                    options={workspaceOptions}
                                    value={workspaceOptions.find(option => option.value === selectedWorkspaceId)}
                                    onChange={option => setSelectedWorkspaceId(option?.value)}
                                    placeholder="Select a workspace"
                                />
                            </div>
                        )}
                    </>
                </div>
                <DialogFooter>
                    <Button mode="borderless" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="blue"
                        mode="dark"
                        disabled={
                            (isOktaConnected ? !selectedUsers.length : !email) ||
                            (isWorkspacesEnabled && !workspaceId && !selectedWorkspaceId)
                        }
                        type="submit"
                        onClick={() => {
                            const targetWorkspaceId = workspaceId || selectedWorkspaceId;
                            if (isOktaConnected) {
                                addMembers.mutate({
                                    userIds: selectedUsers.map(u => u.id),
                                    workspaceId: targetWorkspaceId,
                                });
                            } else {
                                addMembers.mutate({ userIds: [], email, workspaceId: targetWorkspaceId });
                            }
                            onClose();
                        }}
                    >
                        Add Member{isOktaConnected ? 's' : ''}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
