import { ApprovalSelect } from '@/components/ApproverSelect';
import { Preview } from '@/components/policies/PolicySteps/PolicyStepApproval';
import { useApprovalLabels } from '@/components/policies/PolicySteps/labels';
import { Button } from '@/components/ui/button/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/form';
import { Select } from '@/components/ui/select';
import { useDefaultPolicySettingsPut } from '@/hooks/mutations/policies/userDefaultPolicySettingsPut';
import { useDefaultPolicyConfig, usePolicyCustomDurationOptions } from '@/hooks/queries/usePolicies';
import { cn } from '@/lib/styling';
import { useApprovalSettings } from '@/stores/useApprovalSettings';
import { makeDurationOptions } from 'lib/models/app_policy';
import { nudgeCounts, nudgeIntervals } from 'lib/models/app_policy';
import { AppPolicyDuration, type AppPolicyDurationType, AppPolicyReviewStrategy } from 'lib/prisma/enums';
import { capitalize } from 'lodash-es';
import { BadgeCheck, CirclePlus, Clock } from 'lucide-react';
import type React from 'react';
import { useEffect, useState } from 'react';
import { AddCustomAccessLengthModal, DeleteCustomAccessLengthModal } from './CustomAccessLengthModals';

export const SectionHeader = ({
    heading,
    description,
    icon,
    iconBg,
}: { heading: string; description: string; icon: React.ReactNode; iconBg: string }) => {
    return (
        <div className="flex items-center gap-lg border-b border-grey p-lg">
            <div className={cn('p-2 rounded-full', iconBg)}>{icon}</div>
            <div>
                <h2>{heading}</h2>
                <p>{description}</p>
            </div>
        </div>
    );
};

export const LabelDescription = ({
    label,
    description,
    forId,
    children,
}: { label: string; description: string; forId: string; children?: React.ReactNode }) => {
    return (
        <div className="flex flex-col">
            <Label htmlFor={forId} className="text-grey font-semibold">
                {label}
            </Label>
            <p className="mb-sm">{description}</p>
            {children}
        </div>
    );
};

const PendingApprovalSettings = () => {
    const store = useApprovalSettings();

    const { mutate: updateSettings } = useDefaultPolicySettingsPut();

    useEffect(() => {
        if (store.isDirty() && store.settings) {
            updateSettings(store.settings);
            store.init(store.settings);
        }
    }, [store.settings, updateSettings, store.isDirty, store.init]);

    return (
        <>
            <SectionHeader
                heading="Pending approvals"
                description="How Console will handle reviewers who are OOO or fail to respond in a timely manner"
                icon={<Clock />}
                iconBg="bg-bg-yellow-secondary"
            />
            <form className="p-lg flex flex-col gap-lg">
                <LabelDescription
                    label="Nudge reviewer every..."
                    description="How often should we nudge someone about pending approvals that require their review?"
                    forId="nudge-interval"
                >
                    <Select
                        className="lg:w-2/5 max-w-sm"
                        id="nudge-interval"
                        options={nudgeIntervals}
                        value={nudgeIntervals.find(
                            i => i.value === store.currentSettings?.pendingApprovalNudgeInterval,
                        )}
                        onChange={v => store.setSettings({ pendingApprovalNudgeInterval: v?.value })}
                    />
                </LabelDescription>
                <LabelDescription
                    label="Escalate after..."
                    description="How many times should we nudge someone before we escalate the request?"
                    forId="escalate-count"
                >
                    <Select
                        className="lg:w-2/5 max-w-sm"
                        id="escalate-count"
                        options={nudgeCounts}
                        value={nudgeCounts.find(v => v.value === store.currentSettings?.pendingApprovalNudgeCount)}
                        onChange={v => store.setSettings({ pendingApprovalNudgeCount: v?.value })}
                    />
                </LabelDescription>
                <LabelDescription
                    label="Escalate to..."
                    description="Who should we escalate requests to by default when the request has not been approved in time?"
                    forId="escalate-to"
                >
                    <ApprovalSelect
                        className="lg:w-2/5 max-w-sm"
                        id="escalate-to"
                        strategy={
                            store.currentSettings?.pendingApprovalEscalationStrategy ??
                            AppPolicyReviewStrategy.APP_OWNERS
                        }
                        userIds={store.currentSettings?.pendingApprovalEscalationUserIds ?? []}
                        groupIds={store.currentSettings?.pendingApprovalEscalationGroupIds ?? []}
                        onStrategy={strategy => store.setSettings({ pendingApprovalEscalationStrategy: strategy })}
                        onUsers={userIds => store.setSettings({ pendingApprovalEscalationUserIds: userIds })}
                        onGroups={groupIds => store.setSettings({ pendingApprovalEscalationGroupIds: groupIds })}
                        excludedStrategies={[AppPolicyReviewStrategy.APPROVERS_MANAGER]}
                    />
                </LabelDescription>
            </form>
        </>
    );
};

export const Approvals = () => {
    const [showAddCustomDurationModal, setShowAddCustomDurationModal] = useState(false);
    const { data: config } = useDefaultPolicyConfig();
    const store = useApprovalSettings();

    const userOpts = new Set<AppPolicyDurationType>();
    config?.settings?.defaultPolicyDurationOptions?.forEach(d => userOpts.add(d));

    const durations = makeDurationOptions(window.navigator.language);
    const { data: customDurationOptions } = usePolicyCustomDurationOptions();

    const [userDefinedDurations, setUserDefinedDurations] = useState(new Set(userOpts));
    const [userDefinedCustomDurations, setUserDefinedCustomDurations] = useState<Set<string>>(new Set());
    const userDefinedDurationOptions = durations.filter(d => d.value !== AppPolicyDuration.USER_DEFINED);
    const userDefinedDurationOptionsWithCustom = [
        ...userDefinedDurationOptions.map(d => ({ ...d, type: 'built-in' as const })),
        ...(customDurationOptions ?? []).map(d => ({
            label: `${d.duration_value} ${d.duration_type.toLowerCase()}`,
            value: d.id,
            type: 'custom' as const,
        })),
    ];

    const durationsWithCustom = [
        ...durations.map(d => ({ ...d, type: 'built-in' as const })),
        ...(customDurationOptions ?? []).map(d => ({
            label: `${d.duration_value} ${d.duration_type}`,
            value: d.id,
            type: 'custom' as const,
        })),
    ];

    const duration = durationsWithCustom.find(d =>
        store?.settings?.defaultPolicyDuration === AppPolicyDuration.CUSTOM
            ? d.value === store?.settings?.defaultPolicyCustomDurationId
            : d.value === store?.settings?.defaultPolicyDuration,
    ) ?? { ...durations[0], type: 'built-in' as const };

    useEffect(() => {
        if (config?.settings) {
            setUserDefinedDurations(new Set(config.settings.defaultPolicyDurationOptions));
            setUserDefinedCustomDurations(new Set(config.settings.defaultPolicyCustomDurationOptionIds));
            store.init(config.settings);
        }
    }, [config, store.init]);

    const { mutate: updateSettings } = useDefaultPolicySettingsPut();

    useEffect(() => {
        if (store.isDirty() && store.settings) {
            updateSettings(store.settings);
            store.init(store.settings);
        }
    }, [store.settings, updateSettings, store.isDirty, store.init]);

    const handleBuiltInCheck = (checked: boolean, duration: AppPolicyDurationType) => {
        if (checked) {
            userDefinedDurations.add(duration);
        } else {
            userDefinedDurations.delete(duration);
        }
        setUserDefinedDurations(new Set(userDefinedDurations));
        store.setSettings({ defaultPolicyDurationOptions: Array.from(userDefinedDurations) });
    };

    const handleCustomCheck = (checked: boolean, durationId: string) => {
        if (checked) {
            userDefinedCustomDurations.add(durationId);
        } else {
            userDefinedCustomDurations.delete(durationId);
        }
        setUserDefinedCustomDurations(new Set(userDefinedCustomDurations));
        store.setSettings({ defaultPolicyCustomDurationOptionIds: Array.from(userDefinedCustomDurations) });
    };
    const fallbackLabels = useApprovalLabels(
        store.currentSettings?.defaultPolicyEscalationUserIds ?? [],
        store.currentSettings?.defaultPolicyEscalationGroupIds ?? [],
    );

    return (
        <>
            <div className="flex flex-col gap-xl">
                <section className="border-grey border rounded-md">
                    <SectionHeader
                        heading="Default approval settings"
                        description="Define which settings you would like to appear by default when creating an access policy"
                        icon={<BadgeCheck className="text-white" />}
                        iconBg="bg-bg-green-secondary"
                    />
                    <form className="p-lg flex flex-col gap-lg">
                        <div className="flex flex-col gap-sm">
                            <Label htmlFor="default-approver" className="text-grey font-semibold">
                                Default approver
                            </Label>
                            <ApprovalSelect
                                className="lg:w-2/5 max-w-sm"
                                id="default-approver"
                                strategy={
                                    store.currentSettings?.defaultPolicyReviewStrategy ??
                                    AppPolicyReviewStrategy.APP_OWNERS
                                }
                                userIds={store.currentSettings?.defaultPolicyUserReviewerIds ?? []}
                                groupIds={store.currentSettings?.defaultPolicyGroupReviewerIds ?? []}
                                onStrategy={strategy => store.setSettings({ defaultPolicyReviewStrategy: strategy })}
                                onUsers={userIds => store.setSettings({ defaultPolicyUserReviewerIds: userIds })}
                                onGroups={groupIds => store.setSettings({ defaultPolicyGroupReviewerIds: groupIds })}
                                excludedStrategies={[AppPolicyReviewStrategy.APPROVERS_MANAGER]}
                            />
                        </div>
                        <div className="flex flex-col gap-sm">
                            <div className="flex flex-col gap-sm">
                                <div className="flex gap-sm">
                                    <Label htmlFor="duration-select" className="text-grey font-semibold">
                                        Custom access lengths
                                    </Label>
                                    <Button
                                        type="button"
                                        mode="borderless"
                                        size="sm"
                                        onClick={() => setShowAddCustomDurationModal(true)}
                                    >
                                        <CirclePlus />
                                    </Button>
                                </div>
                                <span className="text-grey text-xs">
                                    Custom access lengths allow you to set specific access lengths for your policies
                                </span>
                            </div>
                            <div className="flex gap-md flex-wrap">
                                {customDurationOptions?.map(d => (
                                    <div className="flex items-center gap-sm" key={d.id}>
                                        <p>{d.duration_value}</p>
                                        <p>{capitalize(d.duration_type.toLowerCase())}</p>
                                        <DeleteCustomAccessLengthModal id={d.id} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex flex-col gap-sm">
                            <Label htmlFor="duration-select" className="text-grey font-semibold">
                                Default access length
                            </Label>
                            <Select
                                className="lg:w-2/5 max-w-sm"
                                id="duration-select"
                                options={durationsWithCustom}
                                value={duration}
                                onChange={v =>
                                    store.setSettings({
                                        defaultPolicyDuration:
                                            v?.type === 'built-in' ? v.value : AppPolicyDuration.CUSTOM,
                                        defaultPolicyCustomDurationId: v?.type === 'custom' ? v.value : null,
                                    })
                                }
                            />
                        </div>
                        <div>
                            <label htmlFor={`escalate`} className="font-medium flex gap-md items-start leading-none">
                                <Checkbox
                                    checked={store.currentSettings?.defaultPolicyEscalationEnabled}
                                    onCheckedChange={checked =>
                                        store.setSettings({
                                            defaultPolicyEscalationEnabled: !!checked,
                                        })
                                    }
                                    id={`escalate`}
                                />
                                <div>
                                    Escalate
                                    <p className="text-body-subtle leading-normal">
                                        Escalate if the request has not been approved in time
                                    </p>
                                </div>
                            </label>
                        </div>
                        {store.currentSettings?.defaultPolicyEscalationEnabled && (
                            <div>
                                <ApprovalSelect
                                    strategy={store.currentSettings?.defaultPolicyEscalationStrategy}
                                    userIds={store.currentSettings?.defaultPolicyEscalationUserIds ?? []}
                                    groupIds={store.currentSettings?.defaultPolicyEscalationGroupIds ?? []}
                                    onStrategy={strategy =>
                                        store.setSettings({ defaultPolicyEscalationStrategy: strategy })
                                    }
                                    onUsers={userIds => store.setSettings({ defaultPolicyEscalationUserIds: userIds })}
                                    onGroups={groupIds =>
                                        store.setSettings({ defaultPolicyEscalationGroupIds: groupIds })
                                    }
                                />
                                {fallbackLabels && (
                                    <Preview
                                        strategy={
                                            store.currentSettings?.defaultPolicyEscalationStrategy ??
                                            AppPolicyReviewStrategy.APP_OWNERS
                                        }
                                        labels={fallbackLabels}
                                        owners={[]}
                                    />
                                )}
                            </div>
                        )}
                        {duration.value === AppPolicyDuration.USER_DEFINED && (
                            <div className="flex flex-col gap-sm">
                                <p>Select the default options you want to allow the user to choose from</p>
                                <div className="flex gap-lg flex-wrap">
                                    {userDefinedDurationOptionsWithCustom.map(d => (
                                        <div key={d.value} className="flex items-center gap-sm">
                                            <Checkbox
                                                id={d.value}
                                                checked={
                                                    d.type === 'built-in'
                                                        ? userDefinedDurations.has(d.value)
                                                        : userDefinedCustomDurations.has(d.value)
                                                }
                                                className="data-[state=checked]:bg-bg-blue-secondary"
                                                onCheckedChange={checked =>
                                                    d.type === 'built-in'
                                                        ? handleBuiltInCheck(!!checked, d.value)
                                                        : handleCustomCheck(!!checked, d.value)
                                                }
                                            />
                                            <Label htmlFor={d.value}>{d.label}</Label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </form>
                </section>
                <section className="border-grey border rounded-md">
                    <PendingApprovalSettings />
                </section>
            </div>
            <AddCustomAccessLengthModal
                showModal={showAddCustomDurationModal}
                setShowModal={setShowAddCustomDurationModal}
            />
        </>
    );
};
