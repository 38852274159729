import { useGroups } from '@/hooks/queries/useGroups';
import { useUsers } from '@/hooks/queries/useUsers';

export const useApprovalLabels = (userIds: string[], groupIds: string[]) => {
    const { data: users } = useUsers({ userIds, enabled: userIds.length > 0 });
    const { data: groups } = useGroups({ groupIds });

    const userItems = users?.items ?? [];
    const groupItems = groups?.items ?? [];

    const labels =
        userItems
            .map(u => u.displayName)
            .concat(groupItems.map(g => g.name ?? ''))
            .join(', ') ?? '';

    return labels;
};
