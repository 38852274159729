import { CircleX, Search } from 'lucide-react';
import { useEffect, useState } from 'react';

import { ResLink } from '@/components/ResLink';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar/avatar';
import { Badge } from '@/components/ui/badge/badge';
import {
    DropdownMenuCheckboxItem,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { initials } from '@/lib/initials';

type EntityType = 'users' | 'groups';

// Define a minimal base type that all entity types must have
// The id is the only required field, other fields can be accessed via getters
type BaseEntity = {
    id: string;
    [key: string]: unknown;
};

// Helper function to get display name from an entity, handling different property names
function getDisplayName(entity: BaseEntity): string {
    return (entity.displayName as string) || (entity.name as string) || '';
}

// Helper function to get avatar from an entity
function getAvatar(entity: BaseEntity): string | null | undefined {
    return (
        (entity.avatar as string | null | undefined) ||
        (entity.logo as string | null | undefined) ||
        (entity.image as string | null | undefined)
    );
}

interface SearchableEntityDropdownProps<T extends BaseEntity> {
    entityType: EntityType;
    selectedIds: string[];
    searchResults: T[];
    isLoading: boolean;
    searchTerm: string;
    setSearchTerm: (term: string) => void;
    onSelect: (id: string, add: boolean) => void;
    label: string;
    icon: React.ReactNode;
    showBadgeCount?: boolean;
    renderEntity?: (entity: T) => React.ReactNode;
}

// A simplified avatar component that works with our BaseEntity interface
const EntityAvatar = ({ entity }: { entity: BaseEntity }) => {
    const displayName = getDisplayName(entity);
    const avatarSrc = getAvatar(entity);
    const initStr = initials(displayName);
    return (
        <Avatar size="sm">
            <AvatarImage src={avatarSrc || undefined} alt={initStr} />
            <AvatarFallback>{initStr}</AvatarFallback>
        </Avatar>
    );
};

export const SearchableEntityDropdown = <T extends BaseEntity>({
    entityType,
    selectedIds,
    searchResults,
    isLoading,
    searchTerm,
    setSearchTerm,
    onSelect,
    label,
    icon,
    showBadgeCount = true,
    renderEntity,
}: SearchableEntityDropdownProps<T>) => {
    const [prevSearchResults, setPrevSearchResults] = useState<T[]>([]);

    // Update previous results when new results come in
    useEffect(() => {
        if (searchResults && searchResults.length > 0) {
            setPrevSearchResults(searchResults);
        }
    }, [searchResults]);

    // Use previous results while loading
    const displayResults =
        isLoading && prevSearchResults.length > 0 && searchTerm !== '' ? prevSearchResults : searchResults || [];

    const selectedEntities = displayResults.filter(entity => selectedIds.includes(entity.id));
    const unselectedEntities = displayResults.filter(entity => !selectedIds.includes(entity.id));

    const handleClearSearch = () => {
        setSearchTerm('');
    };

    // Default rendering logic for entities based on type
    const defaultRenderEntity = (entity: T) => {
        if (entityType === 'users') {
            return (
                <>
                    <EntityAvatar entity={entity} />
                    <span>{getDisplayName(entity)}</span>
                </>
            );
        } else {
            return <ResLink label={getDisplayName(entity)} entity="groups" src={getAvatar(entity)} />;
        }
    };

    // Use custom render function if provided, otherwise use default
    const renderEntityContent = renderEntity || defaultRenderEntity;

    return (
        <DropdownMenuSub>
            <DropdownMenuSubTrigger className="flex items-center">
                <div className="flex w-full items-center justify-between">
                    <div className="gap-x-sm flex items-center">
                        {icon}
                        {label}
                    </div>
                    {showBadgeCount && selectedIds.length > 0 && (
                        <Badge className="mr-sm">{selectedIds.length} selected</Badge>
                    )}
                </div>
            </DropdownMenuSubTrigger>
            <DropdownMenuSubContent className="w-[275px]" collisionPadding={10}>
                <div className="bg-bg-overlay pl-sm relative flex items-center rounded-sm border-grey border-[0.5px]">
                    <Search className="opacity-50" />
                    <Input
                        value={searchTerm}
                        placeholder={label}
                        onChange={e => setSearchTerm(e.currentTarget.value)}
                        className="bg-bg-overlay border-none"
                    />
                    {searchTerm.length > 0 && (
                        <button className="right-sm absolute">
                            <CircleX onClick={handleClearSearch} />
                        </button>
                    )}
                </div>
                {selectedEntities.length > 0 && <DropdownMenuSeparator />}
                <div className="max-h-[400px] overflow-y-auto overflow-x-hidden">
                    {selectedEntities.map(entity => (
                        <DropdownMenuCheckboxItem
                            textValue="**" // hack so that typing in search doesn't grab the item
                            key={entity.id}
                            className="dropdown-item gap-x-sm flex items-center hover:bg-bg-overlay"
                            checked={true}
                            side="right"
                            onSelect={e => e.preventDefault()}
                            onCheckedChange={checked => onSelect(entity.id, checked)}
                        >
                            <div className="gap-md ml-md flex items-center">{renderEntityContent(entity)}</div>
                        </DropdownMenuCheckboxItem>
                    ))}
                    {selectedEntities.length > 0 && <DropdownMenuSeparator />}

                    {unselectedEntities.map(entity => (
                        <DropdownMenuCheckboxItem
                            textValue="**" // hack so that typing in search doesn't grab the item
                            key={entity.id}
                            className="gap-x-sm flex items-center hover:bg-bg-overlay"
                            checked={false}
                            side="right"
                            onSelect={e => e.preventDefault()}
                            onCheckedChange={checked => onSelect(entity.id, checked)}
                        >
                            <div className="gap-md ml-md flex items-center">{renderEntityContent(entity)}</div>
                        </DropdownMenuCheckboxItem>
                    ))}
                    {unselectedEntities.length === 0 && searchTerm.length > 0 && (
                        <DropdownMenuItem disabled>
                            No {entityType} found for "{searchTerm}"
                        </DropdownMenuItem>
                    )}
                </div>
            </DropdownMenuSubContent>
        </DropdownMenuSub>
    );
};
