import LoadMore from '@/components/LoadMore';
import type { InfiniteData } from '@tanstack/react-query';
import type { InfiniteQueryObserverResult } from '@tanstack/react-query';
import type {
    GetMembersResponse,
    SchemaSuspendMemberType,
    SchemaUnsuspendMemberType,
    SchemaUpdateMemberType,
} from 'lib/models/members';
import type { InvitedUser } from 'lib/prisma/types';
import type React from 'react';
import { InvitedMemberRow, MemberRow } from './MemberRow';
import { AddMemberModal } from './modals';
interface MembersListProps {
    filteredInvites: InvitedUser[] | undefined;
    members: GetMembersResponse['items'];
    suspended: GetMembersResponse['items'];
    isAdmin: boolean;
    isFetching: boolean;
    hasNextPage: boolean;
    fetchNextPage: () => Promise<InfiniteQueryObserverResult<InfiniteData<GetMembersResponse, unknown>, Error>>;
    isAddMemberModalOpen: boolean;
    setIsAddMemberModalOpen: (isOpen: boolean) => void;
    cbs: {
        changeRole: (_: SchemaUpdateMemberType & { username: string }) => void;
        suspendMember: (_: SchemaSuspendMemberType) => void;
        reinstateMember: (_: SchemaUnsuspendMemberType) => void;
    };
    currentWorkspaceId?: string;
}

export const MembersList: React.FC<MembersListProps> = ({
    filteredInvites,
    members,
    suspended,
    isAdmin,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isAddMemberModalOpen,
    setIsAddMemberModalOpen,
    cbs,
    currentWorkspaceId,
}) => {
    const filteredMembers = currentWorkspaceId
        ? members.filter(m => m.member.workspace_member.some(wm => wm.workspaceId === currentWorkspaceId))
        : members;
    const filteredSuspended = currentWorkspaceId
        ? suspended.filter(m => m.member.workspace_member.some(wm => wm.workspaceId === currentWorkspaceId))
        : suspended;

    return (
        <>
            <div className="border-grey rounded-md border-[0.5px] overflow-visible">
                {!!filteredInvites?.length && isAdmin && (
                    <>
                        <div className="bg-bg-overlay p-md border-grey border-y-[0.5px] text-xs font-medium rounded-t-md">
                            Pending Invites
                        </div>
                        <div className="flex flex-col">
                            {filteredInvites.map(invite => (
                                <InvitedMemberRow key={invite.id} invite={invite} />
                            ))}
                        </div>
                    </>
                )}
                <div className="bg-bg-overlay p-md border-grey border-b-[0.5px] text-xs font-medium">
                    <div className="flex items-center justify-between">
                        <span>Active Members</span>
                        {isAdmin && (
                            <button
                                className="flex items-center justify-center h-5 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
                                onClick={() => setIsAddMemberModalOpen(true)}
                            >
                                <div className="text-gray-500 font-medium text-xs">+ Add Member</div>
                            </button>
                        )}
                    </div>
                </div>
                <div className="flex flex-col">
                    {filteredMembers.map(m => (
                        <MemberRow
                            key={m.member.id}
                            disabled={!isAdmin}
                            currentWorkspaceId={currentWorkspaceId}
                            {...m}
                            {...cbs}
                        />
                    ))}
                </div>
                {!!filteredSuspended.length && (
                    <div className="bg-bg-overlay p-md border-grey border-y-[0.5px] text-xs font-medium">
                        Suspended Members
                    </div>
                )}
                <div className="gap-md flex flex-col">
                    {filteredSuspended.map(m => (
                        <MemberRow
                            key={m.member.id}
                            disabled={false}
                            currentWorkspaceId={currentWorkspaceId}
                            {...m}
                            {...cbs}
                        />
                    ))}
                </div>
            </div>
            <LoadMore
                shouldLoadMore={!isFetching || hasNextPage}
                cb={async () => {
                    await fetchNextPage();
                }}
            />
            <AddMemberModal
                isOpen={isAddMemberModalOpen}
                onClose={() => setIsAddMemberModalOpen(false)}
                workspaceId={currentWorkspaceId}
            />
        </>
    );
};
