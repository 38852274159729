import { ChevronDown, CircleDashed, UserRoundSearch } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';

import { ResLink } from '@/components/ResLink';
import { SearchableEntityDropdown } from '@/components/SearchableEntityDropdown';
import { Button, type ButtonProps } from '@/components/ui/button/button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useGroups } from '@/hooks/queries/useGroups';
import { useUsers } from '@/hooks/queries/useUsers';
import { CnslGroupIcon } from '@/lib/iconConstants';
import { cn } from '@lib/styling';
import { prettyReviewStrategy } from 'lib/models/app_policy';
import { AppPolicyReviewStrategy, type AppPolicyReviewStrategyType } from 'lib/prisma/enums';
import type { CnslGroup, CnslUser } from 'lib/prisma/types';
import { useDebounce } from 'react-use';

interface StrategyConfig {
    strategy: AppPolicyReviewStrategyType;
    label: string;
    Icon: React.ComponentType;
    top: boolean;
}

const allStrategyOpts: StrategyConfig[] = [
    {
        strategy: AppPolicyReviewStrategy.APPROVERS_MANAGER,
        label: prettyReviewStrategy(AppPolicyReviewStrategy.APPROVERS_MANAGER),
        Icon: UserRoundSearch,
        top: true,
    },
    {
        strategy: AppPolicyReviewStrategy.APP_OWNERS,
        label: prettyReviewStrategy(AppPolicyReviewStrategy.APP_OWNERS),
        Icon: UserRoundSearch,
        top: true,
    },
    {
        strategy: AppPolicyReviewStrategy.REQUESTERS_MANAGER,
        label: prettyReviewStrategy(AppPolicyReviewStrategy.REQUESTERS_MANAGER),
        Icon: UserRoundSearch,
        top: true,
    },
    {
        strategy: AppPolicyReviewStrategy.NO_REVIEW_REQUIRED,
        label: prettyReviewStrategy(AppPolicyReviewStrategy.NO_REVIEW_REQUIRED),
        Icon: CircleDashed,
        top: true,
    },
    {
        strategy: AppPolicyReviewStrategy.EXPLICIT,
        label: 'Users',
        Icon: UserRoundSearch,
        top: false,
    },
    {
        strategy: AppPolicyReviewStrategy.EXPLICIT,
        label: 'Groups',
        Icon: CnslGroupIcon,
        top: false,
    },
];

interface ApprovalSelectProps extends ButtonProps {
    strategy: AppPolicyReviewStrategyType;
    userIds: string[];
    groupIds: string[];
    excludedStrategies?: AppPolicyReviewStrategyType[];
    alwaysAllowExplicit?: boolean;
    onStrategy: (strategy: AppPolicyReviewStrategyType) => void;
    onUsers: (userIds: string[]) => void;
    onGroups: (groupIds: string[]) => void;
}

export function ApprovalSelect({
    strategy,
    userIds,
    groupIds,
    onStrategy,
    onUsers,
    onGroups,
    excludedStrategies,
    alwaysAllowExplicit,
    ...props
}: ApprovalSelectProps) {
    // For fetching selected users and groups
    const { data: selectedUserResults } = useUsers({ userIds, enabled: userIds.length > 0 });
    const { data: selectedGroupResults } = useGroups({ groupIds });

    // For search functionality
    const [searchTermUser, setSearchTermUser] = useState('');
    const [searchTermGroup, setSearchTermGroup] = useState('');
    const [debouncedUserTerm, setDebouncedUserTerm] = useState(searchTermUser);
    const [debouncedGroupTerm, setDebouncedGroupTerm] = useState(searchTermGroup);

    // Set up debounce effects
    useDebounce(() => setDebouncedUserTerm(searchTermUser), 200, [searchTermUser]);
    useDebounce(() => setDebouncedGroupTerm(searchTermGroup), 200, [searchTermGroup]);

    // Keep track of search results
    const [prevUserSearchResults, setPrevUserSearchResults] = useState<CnslUser[]>([]);
    const [prevGroupSearchResults, setPrevGroupSearchResults] = useState<CnslGroup[]>([]);

    // API calls for search
    const { data: userSearchResults, isLoading: isLoadingUsers } = useUsers({
        search: debouncedUserTerm,
        enabled: true,
    });

    const { data: groupSearchResults, isLoading: isLoadingGroups } = useGroups({
        search: debouncedGroupTerm,
    });

    // Update previous results when new results come in
    useEffect(() => {
        if (userSearchResults?.items && userSearchResults.items.length > 0) {
            setPrevUserSearchResults(userSearchResults.items);
        }
    }, [userSearchResults]);

    useEffect(() => {
        if (groupSearchResults?.items && groupSearchResults.items.length > 0) {
            setPrevGroupSearchResults(groupSearchResults.items);
        }
    }, [groupSearchResults]);

    // Use previous results while loading
    const searchUsers =
        isLoadingUsers && prevUserSearchResults.length > 0 && searchTermUser !== ''
            ? prevUserSearchResults
            : userSearchResults?.items || [];

    const searchGroups =
        isLoadingGroups && prevGroupSearchResults.length > 0 && searchTermGroup !== ''
            ? prevGroupSearchResults
            : groupSearchResults?.items || [];

    const selectedUsers = selectedUserResults?.items || [];
    const selectedGroups = selectedGroupResults?.items || [];

    const explicitSelections = selectedUsers
        .map(user => ({
            type: 'users',
            id: user.id,
            displayName: user.displayName,
            avatar: user.avatar,
        }))
        .concat(
            selectedGroups.map(group => ({
                type: 'groups',
                id: group.id,
                displayName: group.name ?? '',
                avatar: group.logo ?? '',
            })),
        );

    const explicitSelectionLabel = useMemo(() => {
        const groupCount = selectedGroups.length;
        const userCount = selectedUsers.length;
        if (groupCount > 0 && userCount === 0) {
            return `${groupCount} Group${groupCount > 1 ? 's' : ''}`;
        }
        if (userCount > 0 && groupCount === 0) {
            return `${userCount} User${userCount > 1 ? 's' : ''}`;
        }
        return `${userCount} user${userCount !== 1 ? 's' : ''} and ${groupCount} group${groupCount !== 1 ? 's' : ''}`;
    }, [selectedGroups, selectedUsers]);

    const onUserSelect = (userId: string, add: boolean) => {
        // If adding, concat the new ID with existing IDs
        // If removing, filter out the ID
        const newUserIds = add ? [...userIds, userId] : userIds.filter(id => id !== userId);

        onUsers(newUserIds);
        onStrategy(AppPolicyReviewStrategy.EXPLICIT);
    };

    const onGroupSelect = (groupId: string, add: boolean) => {
        // If adding, concat the new ID with existing IDs
        // If removing, filter out the ID
        const newGroupIds = add ? [...groupIds, groupId] : groupIds.filter(id => id !== groupId);

        onGroups(newGroupIds);
        onStrategy(AppPolicyReviewStrategy.EXPLICIT);
    };

    const excluded = excludedStrategies ?? [];
    const strategyOpts = allStrategyOpts.filter(opt => !excluded.includes(opt.strategy));

    const currentStrat = strategyOpts.find(opt => opt.strategy === strategy) ?? strategyOpts[0];

    const baseLabel = useMemo(() => {
        if (alwaysAllowExplicit) {
            const parts: string[] = [];
            if (strategy === 'APP_OWNERS') {
                parts.push('App owner');
            } else {
                parts.push(currentStrat.label);
            }
            if (selectedGroups.length + selectedUsers.length > 0) {
                parts.push(explicitSelectionLabel);
            }
            return parts.join(' and ');
        }
        return currentStrat.label;
    }, [alwaysAllowExplicit, explicitSelectionLabel, selectedGroups, selectedUsers, strategy, currentStrat.label]);

    const topLevels = strategyOpts.filter(opt => opt.top);
    const userOpt = strategyOpts.find(opt => opt.label === 'Users');
    const groupOpt = strategyOpts.find(opt => opt.label === 'Groups');

    const displayedSelections = explicitSelections.slice(0, 3);
    const leftoverSelectionCount = explicitSelections.length - displayedSelections.length;

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild {...props}>
                <Button className="flex items-center justify-between">
                    {currentStrat.strategy === AppPolicyReviewStrategy.EXPLICIT ? (
                        <div className="flex items-center">
                            {displayedSelections.map((selection, idx) => (
                                <div key={selection.id} className={idx > 0 ? '-ml-sm' : ''}>
                                    <ResLink
                                        entity={selection.type as 'apps' | 'groups'}
                                        src={selection.avatar}
                                        label={selection.displayName}
                                        avatarOnly
                                        size="sm"
                                    />
                                </div>
                            ))}
                            {leftoverSelectionCount > 0 && (
                                <div className="-ml-sm size-4 text-[7px] flex items-center justify-center rounded-full text-white bg-bg-grey-secondary z-10">
                                    <span>+</span>
                                    <span>{leftoverSelectionCount}</span>
                                </div>
                            )}
                            <p className={cn(displayedSelections.length > 0 ? 'ml-sm' : '')}>
                                {explicitSelectionLabel}
                            </p>
                        </div>
                    ) : (
                        <div className="gap-x-sm flex items-center">
                            <currentStrat.Icon />
                            {baseLabel}
                        </div>
                    )}
                    <ChevronDown />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent side="bottom" align="start" className="w-[275px]">
                {topLevels.map(item => (
                    <DropdownMenuItem
                        key={item.strategy}
                        onSelect={() => onStrategy(item.strategy)}
                        className="gap-x-sm flex items-center"
                    >
                        <item.Icon />
                        {item.label}
                    </DropdownMenuItem>
                ))}
                <DropdownMenuSeparator />

                {userOpt && (
                    <SearchableEntityDropdown<CnslUser>
                        entityType="users"
                        selectedIds={userIds}
                        searchResults={searchUsers}
                        isLoading={isLoadingUsers}
                        searchTerm={searchTermUser}
                        setSearchTerm={setSearchTermUser}
                        onSelect={onUserSelect}
                        label={userOpt.label}
                        icon={<userOpt.Icon />}
                    />
                )}

                {groupOpt && (
                    <SearchableEntityDropdown<CnslGroup>
                        entityType="groups"
                        selectedIds={groupIds}
                        searchResults={searchGroups}
                        isLoading={isLoadingGroups}
                        searchTerm={searchTermGroup}
                        setSearchTerm={setSearchTermGroup}
                        onSelect={onGroupSelect}
                        label={groupOpt.label}
                        icon={<groupOpt.Icon />}
                    />
                )}
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
