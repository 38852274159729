import { useQuery } from '@/lib/hooks/useQuery';
import type { PaginatedListResponse } from 'lib/models/pagination';
import type { CnslGroup } from 'lib/prisma/types';
import qs from 'qs';

export const useGroups = (args?: { groupIds?: string[]; search?: string }) => {
    let enabled = true;
    let queryKey: string[] | undefined;
    if (args?.groupIds) {
        enabled = args.groupIds.length > 0;
        queryKey = ['groupsByIds', ...args.groupIds];
    }
    return useQuery<PaginatedListResponse<CnslGroup>>({
        url: `/api/v1/groups?${qs.stringify({ limit: 1000, ids: args?.groupIds, search: args?.search })}`,
        queryKey,
        enabled,
    });
};
