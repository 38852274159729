import { Button } from '@/components/ui/button/button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Label } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useWorkspaceCreate } from '@/hooks/mutations/useWorkspaces';
import { cn } from '@/lib/styling';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

interface CreateWorkspaceModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export function CreateWorkspaceModal({ isOpen, onClose }: CreateWorkspaceModalProps) {
    const { mutate: createWorkspace, isPending } = useWorkspaceCreate();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        setValue,
    } = useForm<{ name: string; identifier: string; description: string }>({
        resolver: zodResolver(
            z.object({
                name: z.string().min(1),
                identifier: z
                    .string()
                    .min(1)
                    .max(16)
                    .regex(/^[a-z0-9]+$/, 'Only lowercase letters and numbers are allowed'),
                description: z.string().min(1),
            }),
        ),
        defaultValues: {
            name: '',
            identifier: '',
            description: '',
        },
    });

    const onSubmit = async (data: { name: string; identifier: string; description: string }) => {
        createWorkspace(data, {
            onSuccess: () => {
                reset();
                onClose();
            },
        });
    };

    return (
        <Dialog
            open={isOpen}
            onOpenChange={open => {
                if (!open) {
                    onClose();
                    reset();
                }
            }}
        >
            <DialogContent className="max-w-md">
                <DialogHeader>
                    <DialogTitle>Create New Workspace</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="p-lg space-y-4">
                        <div>
                            <Label htmlFor="name" className="text-grey font-semibold">
                                Workspace Name
                            </Label>
                            <Input
                                id="name"
                                {...register('name', { required: 'Workspace name is required' })}
                                className={cn(errors.name && 'border-red-500')}
                                placeholder="Enter workspace name"
                                onChange={e => {
                                    // Auto-generate identifier from name
                                    const identifier = e.target.value
                                        .toLowerCase()
                                        .replace(/[^a-z0-9]/g, '')
                                        .slice(0, 16);
                                    setValue('identifier', identifier);
                                }}
                            />
                            {errors.name && <span className="text-red-500 text-sm">{errors.name.message}</span>}
                        </div>
                        <div>
                            <Label htmlFor="identifier" className="text-grey font-semibold">
                                Workspace Identifier
                            </Label>
                            <Input
                                id="identifier"
                                {...register('identifier', { required: 'Workspace identifier is required' })}
                                className={cn(errors.identifier && 'border-red-500')}
                                placeholder="Enter workspace identifier"
                            />
                            {errors.identifier && (
                                <span className="text-red-500 text-sm">{errors.identifier.message}</span>
                            )}
                        </div>
                        <div>
                            <Label htmlFor="description" className="text-grey font-semibold">
                                Description
                            </Label>
                            <Input
                                id="description"
                                {...register('description', { required: 'Workspace description is required' })}
                                className={cn(errors.description && 'border-red-500')}
                                placeholder="Enter workspace description"
                            />
                            {errors.description && (
                                <span className="text-red-500 text-sm">{errors.description.message}</span>
                            )}
                        </div>
                    </div>
                    <DialogFooter>
                        <div className="flex justify-end gap-md grow">
                            <Button
                                type="button"
                                mode="borderless"
                                onClick={() => {
                                    onClose();
                                    reset();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" variant="blue" mode="dark" disabled={isPending}>
                                Create
                            </Button>
                        </div>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
}
