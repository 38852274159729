import { useCallback, useEffect, useState } from 'react';
import { toast } from 'sonner';

import FlaggedFeature from '@/components/FlaggedFeature';
import { Label } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Switch } from '@/components/ui/switch';
import { useUpdateWorkspaceSettings } from '@/hooks/mutations/settings/useWorkspaceSettings';
import { useWorkspaceSettings } from '@/hooks/queries/useWorkspaceSettings';
import { FlagKey } from 'lib/flags/keys';
import { Clock } from 'lucide-react';
import { SectionHeader } from '../Approvals/Approvals';

const SlackTicketReaction = ({ isEnabled: defaultIsEnabled }: { isEnabled: boolean }) => {
    const [isEnabled, setIsEnabled] = useState(defaultIsEnabled);

    useEffect(() => {
        setIsEnabled(defaultIsEnabled);
    }, [defaultIsEnabled]);
    const { mutateAsync: updateWorkspaceSettings } = useUpdateWorkspaceSettings();

    const toggleSlackTicketReaction = useCallback(
        async (enabled: boolean) => {
            setIsEnabled(enabled);
            try {
                await updateWorkspaceSettings({ ticket_reaction: enabled });
            } catch (_error) {
                setIsEnabled(!enabled);
                toast.error('Failed to update Slack ticket reaction setting');
            }
        },
        [updateWorkspaceSettings],
    );
    return (
        <div className="flex flex-col gap-lg">
            <div className="flex items-start gap-lg">
                <Switch checked={isEnabled} onCheckedChange={toggleSlackTicketReaction} />
                <div>
                    <h2 className="font-medium">Enable Slack ticket reaction</h2>
                    <p className="text-xs text-body-subtle-hover">
                        Enabling this setting will automatically react to requests created in Slack with a relevant
                        emoji
                    </p>
                </div>
            </div>
        </div>
    );
};

const Sla = ({ slaHours: defaultSlaHours }: { slaHours: number | null }) => {
    const [slaHours, setSlaHours] = useState(defaultSlaHours || 1);
    const { mutateAsync: updateWorkspaceSettings } = useUpdateWorkspaceSettings();

    useEffect(() => {
        setSlaHours(defaultSlaHours || 1);
    }, [defaultSlaHours]);

    const updateSlaHours = useCallback(
        async (hours: number) => {
            setSlaHours(hours);
            try {
                await updateWorkspaceSettings({ sla_hours: hours });
            } catch (_error) {
                toast.error('Failed to update SLA hours');
            }
        },
        [updateWorkspaceSettings],
    );
    return (
        <FlaggedFeature flag={FlagKey.ResponseTimeSla}>
            <section className="border-grey border rounded-md">
                <SectionHeader
                    heading="Response Time SLA"
                    description="The SLA response time that users should expect when a human is looped in to help with their request"
                    icon={<Clock />}
                    iconBg="bg-bg-blue-secondary text-body-white"
                />
                <div className="flex flex-col p-lg gap-lg">
                    <div className="flex gap-md items-center">
                        <Input
                            type="number"
                            min={1}
                            className="w-24"
                            placeholder="2"
                            value={slaHours}
                            onChange={e => {
                                updateSlaHours(Number(e.target.value));
                            }}
                        />
                        <Label className="text-body-subtle-hover">hours</Label>
                    </div>
                </div>
            </section>
        </FlaggedFeature>
    );
};

export const WorkspaceSettings = () => {
    const { data: settings, isLoading: isSettingsLoading } = useWorkspaceSettings();

    return (
        <>
            {!isSettingsLoading && (
                <>
                    <SlackTicketReaction isEnabled={settings?.ticket_reaction || false} />
                    <Sla slaHours={settings?.sla_hours || null} />
                </>
            )}
        </>
    );
};
