import type { GetMeResponse } from 'lib/models/me';
import { Outlet, RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import type { LoaderFunctionArgs } from 'react-router-dom';

import FlaggedFeature from '@/components/FlaggedFeature';
import { useSubscribeOnboardingFlow } from '@/hooks/useSubscribeOnboardingFlow';
import { baseFetch } from '@/lib/baseFetch';
import { NotFoundPage } from '@/pages/errors/404';
import Home from '@/pages/home';
import InitializeOrg from '@/pages/initialize';
import { PageIndex } from '@/pages/layouts/PageIndex';
import Login from '@/pages/login';
import { Onboarding } from '@/pages/onboarding';
import Settings from '@/pages/settings';
import {
    ConfluenceResourceSelect,
    JiraResourceSelect,
} from '@/pages/settings/components/integrations/JiraResourceSelect';
import SignUp from '@/pages/signup';
import { getDefaultWorkspace, getFirstWorkspace, getOrgMembership, useUser } from '@/stores/useUser';
import { FlagKey } from 'lib/flags';
import type { ApiError } from 'lib/models/error';
import type { PaginatedListResponse } from 'lib/models/pagination';
import type { CnslRequestList } from 'lib/models/request';
import { toast } from 'sonner';
import {
    ACTIONS,
    ACTIONS_CREATE,
    ACTIONS_DETAILS,
    AGENTS,
    AGENT_DETAILS,
    APPS,
    APP_DETAILS,
    ARTICLES,
    ARTICLE_DETAILS,
    EVENTS,
    GOOGLE_OAUTH_CALLBACK,
    GROUPS,
    GROUP_DETAILS,
    INITIALIZE_ORG,
    INSIGHTS,
    JIRA_OAUTH_CALLBACK,
    LOGIN,
    NOTION_OAUTH_CALLBACK,
    OKTA_OAUTH_CALLBACK,
    ONBOARDING_SELF_SERVICE,
    PLAYBOOKS,
    PLAYBOOK_DETAILS,
    PLAYBOOK_TEMPLATES,
    POLICIES,
    POLICY_CREATE,
    POLICY_DETAILS,
    POLICY_DETAILS_APP,
    POLICY_DETAILS_V2,
    REQUESTS,
    REQUESTS_DETAILS,
    ROI,
    ROI_ROOT,
    ROOT,
    ROOT_TO_WORKSPACE_REDIRECT_PATHS,
    SETTINGS,
    SETTINGS_ORGANIZATION_GENERAL,
    SETTINGS_ORGANIZATION_INTEGRATION,
    SETTINGS_WORKSPACE_GENERAL,
    SETUP,
    SETUP_STEP,
    SETUP_STEP_ORG,
    SIGNUP,
    SLACK_CALLBACK,
    SNIPPETS,
    SNIPPET_DETAILS,
    TICKETS,
    TICKET_DETAILS,
    USERS,
    USER_DETAILS,
} from './paths';

export const ME_ROUTE = '/api/v1/me';
export const LATEST_REQUEST_ROUTE = '/api/v1/requests?limit=1';

const getUserAndWorkspace = async (params?: { workspaceIdentifier?: string }) => {
    const state = useUser.getState();
    let user = state.user;

    if (!user) {
        const data = await baseFetch<GetMeResponse>(ME_ROUTE);
        if (!data?.me) {
            return { redirect: redirect('/login') };
        }
        user = data.me;
        state.setUser(user);
    }

    // If a specific workspace is requested, check if it exists
    if (params?.workspaceIdentifier) {
        const workspace = user.orgMemberships
            ?.flatMap(org => org.workspaces)
            .find(w => w.identifier === params.workspaceIdentifier);

        if (!workspace) {
            return {
                error: new Response(`Workspace "${params.workspaceIdentifier}" not found`, {
                    status: 404,
                    statusText: 'Not Found',
                }),
            };
        }

        state.setWorkspace(workspace);
        return { user, workspace: params.workspaceIdentifier };
    }

    // Otherwise, get the default workspace or fall back to the first workspace
    // the user has access to
    const userWorkspace = getDefaultWorkspace(user) || getFirstWorkspace(user);

    if (!userWorkspace) {
        return {
            error: new Response('User does not have access to any workspaces', { status: 404 }),
        };
    }

    state.setWorkspace(userWorkspace);
    return { user, workspace: userWorkspace.identifier };
};

const router = createBrowserRouter(
    [
        {
            element: <PageIndex />,
            ErrorBoundary: NotFoundPage,
            children: [
                {
                    id: 'root',
                    path: '/',
                    Component: Home,
                    loader: async () => {
                        const state = useUser.getState();
                        const data = await baseFetch<GetMeResponse>(ME_ROUTE);

                        if (!data?.me) {
                            state.setUser(null);
                            return redirect(LOGIN);
                        }

                        state.setUser(data.me);
                        return data;
                    },
                    children: [
                        {
                            index: true,
                            Component: Onboarding,
                            loader: async () => {
                                const data = await baseFetch<GetMeResponse>(ME_ROUTE);
                                const membership = getOrgMembership(data.me);
                                const nerfed = membership?.nerfed;

                                // Use the default workspace in the redirect since no workspace provided in URL
                                // If user doesn't have access to default workspace, use the first workspace they have access to
                                const workspace = getDefaultWorkspace(data.me) || getFirstWorkspace(data.me);

                                if (!workspace) {
                                    throw new Error('User does not have access to any workspaces');
                                }

                                if (nerfed) {
                                    const requests =
                                        await baseFetch<PaginatedListResponse<CnslRequestList>>(LATEST_REQUEST_ROUTE);

                                    if (requests?.items.length === 0) {
                                        return redirect('/onboarding/kb');
                                    } else {
                                        return redirect(`/${workspace.identifier}/requests`);
                                    }
                                }

                                return redirect(`/${workspace.identifier}/requests`);
                            },
                        },
                        {
                            path: SETTINGS,
                            Component: Settings,
                            children: [
                                {
                                    index: true,
                                    loader: ({ params }) => {
                                        return redirect(
                                            `/${params.workspaceIdentifier}/${SETTINGS_ORGANIZATION_GENERAL}`,
                                        );
                                    },
                                },
                                {
                                    path: ':group',
                                    element: <Outlet />,
                                    loader: ({ params }) => {
                                        if (params.group === 'workspace') {
                                            return redirect(
                                                `/${params.workspaceIdentifier}/${SETTINGS_WORKSPACE_GENERAL}`,
                                            );
                                        }
                                        return null;
                                    },
                                },
                                {
                                    path: ':group/:setting',
                                    element: <Outlet />,
                                },
                                {
                                    path: ':group/:setting/:subsetting',
                                    element: <Outlet />,
                                },
                            ],
                        },
                        {
                            path: ROI_ROOT,
                            lazy: async () => {
                                const { ROIPage } = await import('@/pages/roi');
                                return {
                                    Component: ROIPage,
                                };
                            },
                        },
                        {
                            path: ROI,
                            lazy: async () => {
                                const { ROIPage } = await import('@/pages/roi');
                                return {
                                    Component: ROIPage,
                                };
                            },
                        },
                        {
                            path: '/settings/organization/integrations/jira/select-resource',
                            Component: JiraResourceSelect,
                        },
                        {
                            path: '/settings/organization/integrations/confluence/select-resource',
                            Component: ConfluenceResourceSelect,
                        },
                        {
                            path: ONBOARDING_SELF_SERVICE,
                            lazy: async () => {
                                const { SelfService } = await import('@/pages/onboarding/SelfService');
                                return {
                                    Component: SelfService,
                                };
                            },
                        },
                        // internal routes use _
                        {
                            path: '_stickersheet',
                            lazy: async () => {
                                const { default: StickerSheet } = await import('@/pages/_stickersheet');
                                return { Component: StickerSheet };
                            },
                        },
                        ...ROOT_TO_WORKSPACE_REDIRECT_PATHS.map(path => ({
                            path,
                            loader: async ({ request }: LoaderFunctionArgs) => {
                                const result = await getUserAndWorkspace();

                                if (result.redirect) return result.redirect;
                                if (result.error) throw result.error;

                                const url = new URL(request.url);
                                return redirect(`/${result.workspace}${url.pathname}${url.search}`);
                            },
                        })),
                        {
                            path: ':workspaceIdentifier',
                            ErrorBoundary: NotFoundPage,
                            loader: async ({ params }) => {
                                const result = await getUserAndWorkspace(params);

                                if (result.redirect) return result.redirect;
                                if (result.error) throw result.error;

                                return null; // Continue to child route
                            },
                            children: [
                                {
                                    index: true,
                                    loader: async ({ params }) => {
                                        return redirect(`/${params.workspaceIdentifier}/requests`);
                                    },
                                },
                                {
                                    path: SETTINGS,
                                    Component: Settings,
                                    children: [
                                        {
                                            index: true,
                                            loader: ({ params }) => {
                                                return redirect(
                                                    `/${params.workspaceIdentifier}/${SETTINGS_ORGANIZATION_GENERAL}`,
                                                );
                                            },
                                        },
                                        {
                                            path: ':group',
                                            element: <Outlet />,
                                            loader: ({ params }) => {
                                                if (params.group === 'workspace') {
                                                    return redirect(
                                                        `/${params.workspaceIdentifier}/${SETTINGS_WORKSPACE_GENERAL}`,
                                                    );
                                                }
                                                return null;
                                            },
                                        },
                                        {
                                            path: ':group/:setting',
                                            element: <Outlet />,
                                        },
                                        {
                                            path: ':group/:setting/:subsetting',
                                            element: <Outlet />,
                                        },
                                    ],
                                },
                                {
                                    path: APPS,
                                    lazy: async () => {
                                        const { Apps } = await import('@/pages/apps');
                                        return {
                                            Component: Apps,
                                        };
                                    },
                                },
                                {
                                    path: SNIPPETS,
                                    lazy: async () => {
                                        const { Snippets } = await import('@/pages/snippets/Root');
                                        return {
                                            Component: Snippets,
                                        };
                                    },
                                },
                                {
                                    path: SNIPPET_DETAILS,
                                    lazy: async () => {
                                        const { SnippetDetails } = await import('@/pages/snippets/SnippetDetails');
                                        return {
                                            Component: SnippetDetails,
                                        };
                                    },
                                },
                                {
                                    path: AGENTS,
                                    lazy: async () => {
                                        const { Agents } = await import('@/pages/agents/Root');
                                        return {
                                            Component: Agents,
                                        };
                                    },
                                },
                                {
                                    path: AGENT_DETAILS,
                                    lazy: async () => {
                                        const { AgentDetails } = await import('@/pages/agents/Details');
                                        return {
                                            Component: AgentDetails,
                                        };
                                    },
                                },
                                {
                                    path: EVENTS,
                                    lazy: async () => {
                                        const { Events } = await import('@/pages/events');
                                        return { Component: Events };
                                    },
                                },
                                {
                                    path: GROUPS,
                                    lazy: async () => {
                                        const { Groups } = await import('@/pages/groups');
                                        return { Component: Groups };
                                    },
                                },
                                {
                                    path: ACTIONS,
                                    lazy: async () => {
                                        const { Playbooks } = await import('@/pages/playbooks');
                                        return { Component: Playbooks };
                                    },
                                },
                                {
                                    path: PLAYBOOK_TEMPLATES,
                                    lazy: async () => {
                                        const { Playbooks } = await import('@/pages/playbooks');
                                        return { Component: Playbooks };
                                    },
                                },
                                {
                                    path: ACTIONS_DETAILS,
                                    lazy: async () => {
                                        const { ActionDetails } = await import('@/pages/playbooks/actions/details');
                                        return { Component: ActionDetails };
                                    },
                                },
                                {
                                    path: ACTIONS_CREATE,
                                    lazy: async () => {
                                        const { ActionCreate } = await import('@/pages/playbooks/actions/details');
                                        return { Component: ActionCreate };
                                    },
                                },
                                {
                                    path: PLAYBOOKS,
                                    lazy: async () => {
                                        const { Playbooks } = await import('@/pages/playbooks');
                                        return { Component: Playbooks };
                                    },
                                },
                                {
                                    path: PLAYBOOK_DETAILS,
                                    lazy: async () => {
                                        const { PlaybookDetails } = await import('@/pages/playbooks/details');
                                        return { Component: PlaybookDetails };
                                    },
                                },
                                {
                                    path: POLICIES,
                                    lazy: async () => {
                                        const { Policies } = await import('@/pages/policies');
                                        return { Component: Policies };
                                    },
                                },
                                {
                                    path: POLICY_DETAILS_APP,
                                    lazy: async () => {
                                        const { Policies } = await import('@/pages/policies');
                                        return { Component: Policies };
                                    },
                                },
                                {
                                    path: POLICY_DETAILS_V2,
                                    lazy: async () => {
                                        const { Policies } = await import('@/pages/policies');
                                        return { Component: Policies };
                                    },
                                },
                                {
                                    path: POLICY_DETAILS,
                                    lazy: async () => {
                                        const { PolicyDetails } = await import('@/pages/policies/details');
                                        return { Component: PolicyDetails };
                                    },
                                },
                                {
                                    path: POLICY_CREATE,
                                    lazy: async () => {
                                        const { PolicyDetails } = await import('@/pages/policies/details');
                                        return { Component: PolicyDetails };
                                    },
                                },
                                {
                                    path: ARTICLES,
                                    lazy: async () => {
                                        const { Articles } = await import('@/pages/articles');
                                        return { Component: Articles };
                                    },
                                },
                                {
                                    path: ARTICLE_DETAILS,
                                    lazy: async () => {
                                        const { ArticleDetails } = await import('@/pages/articles/details');
                                        return { Component: ArticleDetails };
                                    },
                                },
                                {
                                    path: USERS,
                                    lazy: async () => {
                                        const { Users } = await import('@/pages/users');
                                        return { Component: Users };
                                    },
                                },
                                {
                                    path: `${USER_DETAILS}/*`,
                                    lazy: async () => {
                                        const { UserDetails } = await import('@/pages/users/details');
                                        return { Component: UserDetails };
                                    },
                                },
                                {
                                    path: `${APP_DETAILS}/*`,
                                    lazy: async () => {
                                        const { AppDetails } = await import('@/pages/apps/details');
                                        return { Component: AppDetails };
                                    },
                                },
                                {
                                    path: `${GROUP_DETAILS}`,
                                    lazy: async () => {
                                        const { GroupDetails } = await import('@/pages/groups/details');
                                        return { Component: GroupDetails };
                                    },
                                },
                                {
                                    path: REQUESTS,
                                    lazy: async () => {
                                        const { Requests } = await import('@/pages/requests');
                                        return { Component: Requests };
                                    },
                                },
                                {
                                    path: REQUESTS_DETAILS,
                                    lazy: async () => {
                                        const { RequestDetails } = await import('@/pages/requests/details');
                                        return { Component: RequestDetails };
                                    },
                                },
                                {
                                    path: INSIGHTS,
                                    lazy: async () => {
                                        const { Insights } = await import('@/pages/insights');
                                        return { Component: Insights };
                                    },
                                },
                                {
                                    path: TICKETS,
                                    lazy: async () => {
                                        const TicketsPage = await import('@/pages/tickets');
                                        return {
                                            Component: () => (
                                                <FlaggedFeature
                                                    flag={FlagKey.TicketsPage}
                                                    fallback={<div>Tickets page is not available</div>}
                                                >
                                                    <TicketsPage.default />
                                                </FlaggedFeature>
                                            ),
                                        };
                                    },
                                },
                                {
                                    path: TICKET_DETAILS,
                                    lazy: async () => {
                                        const TicketDetailPage = await import('@/pages/tickets/details');
                                        return {
                                            Component: () => (
                                                <FlaggedFeature
                                                    flag={FlagKey.TicketsPage}
                                                    fallback={<div>Ticket details are not available</div>}
                                                >
                                                    <TicketDetailPage.default />
                                                </FlaggedFeature>
                                            ),
                                        };
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    path: LOGIN,
                    loader: async () => {
                        const state = useUser.getState();

                        /**
                         * Handle auth logic
                         */
                        const data = await baseFetch<GetMeResponse>(ME_ROUTE);

                        if (data.me) {
                            state.setUser(data.me);
                            return redirect(ROOT);
                        }
                        return data;
                    },
                    Component: Login,
                },
                {
                    path: `${SIGNUP}/:token`,
                    loader: async () => {
                        const state = useUser.getState();

                        /**
                         * Handle auth logic
                         */
                        const data = await baseFetch<GetMeResponse>(ME_ROUTE);

                        if (data.me) {
                            state.setUser(data.me);
                            return redirect(SETUP_STEP_ORG);
                        }
                        return data;
                    },
                    Component: SignUp,
                },
                {
                    path: SIGNUP,
                    loader: async () => {
                        const state = useUser.getState();

                        /**
                         * Handle auth logic
                         */
                        const data = await baseFetch<GetMeResponse>(ME_ROUTE);

                        if (data.me) {
                            state.setUser(data.me);
                            return redirect(SETUP_STEP_ORG);
                        }
                        return data;
                    },
                    Component: SignUp,
                },
                {
                    path: SETUP,
                    Component: SignUp,
                    loader: async () => {
                        const state = useUser.getState();

                        const data = await baseFetch<GetMeResponse>(ME_ROUTE);

                        if (!data?.me) {
                            state.setUser(null);
                            return redirect(SIGNUP);
                        }
                        state.setUser(data.me);
                        return data;
                    },
                },
                {
                    path: SETUP_STEP,
                    id: SETUP_STEP,
                    Component: SignUp,
                    loader: async () => {
                        const state = useUser.getState();
                        if (!state.user) {
                            const data = await baseFetch<GetMeResponse>(ME_ROUTE);

                            if (!data?.me) {
                                state.setUser(null);
                                return redirect(SIGNUP);
                            }
                            state.setUser(data.me);
                            return data;
                        }

                        return null;
                    },
                },
                {
                    path: SLACK_CALLBACK,
                    async loader(d) {
                        const data = await baseFetch<GetMeResponse>(ME_ROUTE);
                        const membership = getOrgMembership(data.me);

                        const nerfed = membership?.nerfed;

                        const search = d.request.url.split('?')[1];
                        return baseFetch<ApiError | null>(`/api/v1/integrations/slack/callback?${search}`).then(i => {
                            if (i?.error) {
                                if (i.error.code === 400) {
                                    setTimeout(() => {
                                        toast.error(i.error.messages?.join('. '));
                                    }, 1000);
                                }
                            }
                            return redirect(nerfed ? '/onboarding/syncing' : SETTINGS_ORGANIZATION_INTEGRATION);
                        });
                    },
                },
                {
                    path: GOOGLE_OAUTH_CALLBACK,
                    async loader(d) {
                        const search = d.request.url.split('?')[1];
                        const url = new URL(d.request.url);
                        const state = url.searchParams.get('state');
                        if (state === 'signup') {
                            const resp = await baseFetch<
                                | {
                                      error: {
                                          code: number;
                                      };
                                  }
                                | undefined
                            >(`/auth/google/callback?${search}`);
                            if (resp?.error?.code === 400) {
                                return redirect(`${LOGIN}?error=not_work_email`);
                            }
                            return redirect('/');
                        }

                        return baseFetch(`/api/v1/integrations/google/callback?${search}`).then(() =>
                            redirect(SETTINGS_ORGANIZATION_INTEGRATION),
                        );
                    },
                },
                {
                    path: OKTA_OAUTH_CALLBACK,
                    async loader(d) {
                        const search = d.request.url.split('?')[1];

                        return baseFetch(`/api/v1/integrations/okta/callback?${search}`).then(() =>
                            redirect(SETTINGS_ORGANIZATION_INTEGRATION),
                        );
                    },
                },
                {
                    path: NOTION_OAUTH_CALLBACK,
                    async loader(d) {
                        const search = d.request.url.split('?')[1];
                        const url = new URL(d.request.url);
                        const state = url.searchParams.get('state');

                        const redirectPath =
                            state === 'onboarding' ? '/onboarding/slackbot' : SETTINGS_ORGANIZATION_INTEGRATION;

                        return baseFetch(`/api/v1/integrations/notion/callback?${search}`).then(() =>
                            redirect(redirectPath),
                        );
                    },
                },
                {
                    path: JIRA_OAUTH_CALLBACK,
                    async loader(d) {
                        const search = d.request.url.split('?')[1];
                        const { redirectPath } = await baseFetch<{ redirectPath: string }>(
                            `/api/v1/integrations/jira/callback?${search}`,
                        );
                        return redirect(redirectPath ?? SETTINGS_ORGANIZATION_INTEGRATION);
                    },
                },
                {
                    path: INITIALIZE_ORG,
                    Component: InitializeOrg,
                },
            ],
        },
    ],
    {},
);

export default function Routes() {
    useSubscribeOnboardingFlow();

    return <RouterProvider router={router} />;
}
