import { useUser } from '@/stores/useUser';
import { useQuery } from '@lib/hooks/useQuery';
import type { DefaultError } from '@tanstack/react-query';
import type { WorkspaceSetting } from 'lib/prisma/types';

export const useWorkspaceSettings = () => {
    const { workspace } = useUser();
    return useQuery<WorkspaceSetting, DefaultError>({
        url: `/api/v1/workspace-settings`,
        queryKey: ['workspace-settings', workspace?.id ?? ''],
    });
};
