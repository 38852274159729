import { Button } from '@/components/ui/button/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useInvitedUsers } from '@/hooks/queries/useInvitedUsers';
import { useMemberReinstate, useMemberSuspend, useMemberUpdate } from '@/hooks/queries/useMembership';
import { useWorkspaces } from '@/hooks/queries/useWorkspaces';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { baseFetch } from '@/lib/baseFetch';
import { getHeaders } from '@/lib/getHeaders';
import { useUser } from '@/stores/useUser';
import { useInfiniteQuery } from '@tanstack/react-query';
import { FlagKey } from 'lib/flags/keys';
import type { GetMembersResponse } from 'lib/models/members';
import { CnslRole, type CnslRoleType } from 'lib/prisma/enums';
import { Plus, Settings } from 'lucide-react';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { MembersList } from './MembersList';
import { CreateWorkspaceModal, ManageWorkspaceModal } from './modals';

const Members = () => {
    const { user, workspace, setWorkspace } = useUser();
    const isAdmin = user?.orgMemberships[0].role === CnslRole.ADMIN;

    const isWorkspacesEnabled = useFeatureFlag(FlagKey.Workspaces);
    const { data: workspacesRes } = useWorkspaces();
    const [isCreateWorkspaceModalOpen, setIsCreateWorkspaceModalOpen] = useState(false);
    const [selectedWorkspaceForManage, setSelectedWorkspaceForManage] = useState<{
        id: string;
        name: string;
        identifier: string;
        is_default: boolean;
    } | null>(null);
    const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);

    const workspaces = !workspacesRes || 'error' in workspacesRes ? [] : workspacesRes;

    // Initialize selectedTab based on current workspace, but keep it as local state
    const [selectedTab, setSelectedTab] = useState<string>(workspace?.id || 'global');

    // If workspaces are enabled and loaded but no workspace is selected, select the first one
    useEffect(() => {
        if (isWorkspacesEnabled && workspaces.length && !workspace) {
            setWorkspace(workspaces[0]);
            setSelectedTab(workspaces[0].id);
        }
    }, [isWorkspacesEnabled, workspaces, workspace, setWorkspace]);

    // Adjust the query to filter by workspace if workspaceId is provided
    const queryParams = selectedTab !== 'global' ? { workspace_id: selectedTab } : {};

    const { fetchNextPage, hasNextPage, isFetching, data } = useInfiniteQuery<GetMembersResponse>({
        queryKey: ['members', 'list', selectedTab],
        queryFn: ({ pageParam }) =>
            baseFetch<GetMembersResponse>(`/api/v1/members?${qs.stringify({ ...queryParams, offset: pageParam })}`, {
                headers: getHeaders(),
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.pagination.remaining === 0 ? null : lastPage.pagination.offset),
    });

    const { data: invitedUsers } = useInvitedUsers();

    // Filter invited users based on workspace
    const filteredInvites = invitedUsers?.invited.filter(invite => {
        // For the "All Members" view, show all invites
        if (selectedTab === 'global') return true;

        // If invite has a workspaceId, show it only in that workspace view
        if (invite.workspaceId) return invite.workspaceId === selectedTab;

        // If invite has no workspaceId and we're in a workspace view, don't show it
        return false;
    });

    const suspend = useMemberSuspend();
    const reinstate = useMemberReinstate();
    const update = useMemberUpdate();

    /* Endpoint is sorted by suspension, then name so we can split instead juggling two queries */
    const fullData = data?.pages.flatMap(({ items }) => items) || [];
    const splitIdx = fullData.findIndex(({ member }) => member.suspended);
    const members = splitIdx > 0 ? fullData.slice(0, splitIdx) : fullData;
    const suspended = splitIdx > 0 ? fullData.slice(splitIdx) : [];

    const cbs = {
        changeRole: ({ role, memberId, username }: { role: CnslRoleType; memberId: string; username: string }) => {
            void update.mutateAsync({ role, memberId }).then(() => {
                if (username === user?.user?.username) {
                    const updatedUser = {
                        ...user!,
                        orgMemberships: user!.orgMemberships.map(m => {
                            if (m.orgId === user.orgMemberships[0].orgId) {
                                return { ...m, role };
                            }
                            return m;
                        }),
                    };
                    useUser.getState().setUser(updatedUser);
                }
            });
        },
        suspendMember: suspend.mutate,
        reinstateMember: reinstate.mutate,
    };

    if (!isWorkspacesEnabled) {
        return (
            <MembersList
                filteredInvites={filteredInvites}
                members={members}
                suspended={suspended}
                isAdmin={isAdmin}
                isFetching={isFetching}
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
                isAddMemberModalOpen={isAddMemberModalOpen}
                setIsAddMemberModalOpen={setIsAddMemberModalOpen}
                cbs={cbs}
            />
        );
    }

    return (
        <>
            <Tabs
                value={selectedTab}
                onValueChange={value => {
                    setSelectedTab(value);
                }}
                className="w-full"
            >
                <TabsList className="mb-4 flex items-center justify-between">
                    <div className="flex items-center gap-2">
                        {workspaces
                            .sort((a, b) => {
                                // Default workspace should always be first
                                if (a.is_default) return -1;
                                if (b.is_default) return 1;
                                // Otherwise sort alphabetically
                                return a.name.localeCompare(b.name);
                            })
                            .map(w => (
                                <div key={w.id} className="flex items-center">
                                    <TabsTrigger value={w.id} className="px-3">
                                        {w.name}
                                    </TabsTrigger>
                                </div>
                            ))}
                        <TabsTrigger value="global" className="px-3">
                            All Members
                        </TabsTrigger>
                    </div>
                    {isAdmin && (
                        <button
                            onClick={() => setIsCreateWorkspaceModalOpen(true)}
                            className="p-1 rounded-md hover:bg-gray-200 focus:outline-none"
                            title="Create Workspace"
                        >
                            <Plus size={16} aria-hidden="true" />
                        </button>
                    )}
                </TabsList>

                {workspaces
                    .sort((a, b) => {
                        // Default workspace should always be first
                        if (a.is_default) return -1;
                        if (b.is_default) return 1;
                        // Otherwise sort alphabetically
                        return a.name.localeCompare(b.name);
                    })
                    .map(w => (
                        <TabsContent key={w.id} value={w.id}>
                            <div className="mb-2 ml-1">
                                <div className="flex justify-between items-center">
                                    <div className="flex flex-col gap-md">
                                        <div className="flex items-center gap-sm">
                                            <h3 className="text-sm font-semibold">{w.name} Workspace</h3>
                                            {w.is_default && (
                                                <span className="px-2 py-0.5 text-xs rounded-full bg-grey-50 text-body-subtle border border-grey">
                                                    Default
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    {isAdmin && (
                                        <div className="flex items-center gap-sm">
                                            <Button
                                                mode="borderless"
                                                onClick={() => setSelectedWorkspaceForManage(w)}
                                                className="flex items-center gap-sm"
                                                title="Manage Workspace"
                                            >
                                                <Settings className="w-4 h-4" aria-hidden="true" />
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <MembersList
                                filteredInvites={filteredInvites}
                                members={members}
                                suspended={suspended}
                                isAdmin={isAdmin}
                                isFetching={isFetching}
                                hasNextPage={hasNextPage}
                                fetchNextPage={fetchNextPage}
                                isAddMemberModalOpen={isAddMemberModalOpen}
                                setIsAddMemberModalOpen={setIsAddMemberModalOpen}
                                cbs={cbs}
                                currentWorkspaceId={w.id}
                            />
                        </TabsContent>
                    ))}
                <TabsContent value="global">
                    <div className="mb-2 ml-1">
                        <div className="flex justify-between items-center">
                            <div>
                                <h3 className="text-sm font-semibold">All Members</h3>
                                <p className="text-body-subtle text-sm my-1">Viewing members across all workspaces</p>
                            </div>
                        </div>
                    </div>
                    <MembersList
                        filteredInvites={filteredInvites}
                        members={members}
                        suspended={suspended}
                        isAdmin={isAdmin}
                        isFetching={isFetching}
                        hasNextPage={hasNextPage}
                        fetchNextPage={fetchNextPage}
                        isAddMemberModalOpen={isAddMemberModalOpen}
                        setIsAddMemberModalOpen={setIsAddMemberModalOpen}
                        cbs={cbs}
                    />
                </TabsContent>
            </Tabs>

            <CreateWorkspaceModal
                isOpen={isCreateWorkspaceModalOpen}
                onClose={() => setIsCreateWorkspaceModalOpen(false)}
            />

            <ManageWorkspaceModal
                isOpen={!!selectedWorkspaceForManage}
                onClose={() => {
                    setSelectedWorkspaceForManage(null);
                }}
                onDelete={() => {
                    const workspaceId = selectedWorkspaceForManage?.id;
                    if (workspaces.length > 1 && workspaceId) {
                        const nextWorkspace = workspaces.find(w => w.id !== workspaceId);
                        if (nextWorkspace) {
                            setWorkspace(nextWorkspace);
                            if (selectedTab === workspaceId) {
                                setSelectedTab(nextWorkspace.id);
                            }
                        }
                    }
                    setSelectedWorkspaceForManage(null);
                }}
                workspace={selectedWorkspaceForManage}
            />
        </>
    );
};

export default Members;
