import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import type { Prisma } from 'lib/prisma/client';
import type { CustomAppPolicyDuration } from 'lib/prisma/types';
import { toast } from 'sonner';
import { refetch } from './cache';

export const usePolicyCustomDurationCreate = () => {
    const queryClient = useQueryClient();
    return useMutation<void, DefaultError, Omit<CustomAppPolicyDuration, 'id' | 'orgId'>>({
        url: `/api/v1/policies/custom-duration-options`,
        method: 'POST',
        successToast: {
            message: 'Duration added',
        },
        errorToast: {
            message: 'Problem updating policy',
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['custom-duration-options'] });
            refetch(queryClient);
        },
    });
};

export const usePolicyCustomDurationDelete = (id: string) => {
    const queryClient = useQueryClient();
    return useMutation<
        CustomAppPolicyDuration | { policiesThatWouldBreak: Prisma.app_policyGetPayload<{ include: { app: true } }>[] },
        DefaultError,
        void
    >({
        url: `/api/v1/policies/custom-duration-options/${id}`,
        method: 'DELETE',
        errorToast: {
            message: 'Problem deleting duration',
        },
        onSuccess: data => {
            refetch(queryClient);
            queryClient.invalidateQueries({ queryKey: ['custom-duration-options'] });
            if (data && !('policiesThatWouldBreak' in data)) {
                toast.success('Duration deleted');
            }
        },
    });
};
