import { ORG_ID_HEADER_KEY, WORKSPACE_ID_HEADER_KEY } from 'lib/constants';

import { useUser } from '@/stores/useUser';

export const getHeaders = (initHeaders?: Record<string, string>) => {
    const { user, workspace } = useUser.getState();
    const orgId = user?.orgMemberships[0].orgId;
    const workspaceId = workspace?.id;
    const headers: Record<string, string> = initHeaders ?? {};
    if (orgId) {
        headers[ORG_ID_HEADER_KEY] = orgId;
    }
    if (workspaceId) {
        headers[WORKSPACE_ID_HEADER_KEY] = workspaceId;
    }
    return headers;
};
