import { useInvitedUsers } from '@/hooks/queries/useInvitedUsers';
import { useMemberReinstate, useMemberSuspend, useMemberUpdate } from '@/hooks/queries/useMembership';
import { baseFetch } from '@/lib/baseFetch';
import { getHeaders } from '@/lib/getHeaders';
import { useUser } from '@/stores/useUser';
import { useInfiniteQuery } from '@tanstack/react-query';
import type { GetMembersResponse } from 'lib/models/members';
import { CnslRole } from 'lib/prisma/enums';
import type { CnslRoleType } from 'lib/prisma/enums';
import qs from 'qs';
import { useState } from 'react';
import { MembersList } from './MembersList';

export const WorkspaceMembers = () => {
    const { user, workspace } = useUser();
    const isAdmin = user?.orgMemberships[0].role === CnslRole.ADMIN;

    const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);

    const { fetchNextPage, hasNextPage, isFetching, data } = useInfiniteQuery<GetMembersResponse>({
        queryKey: ['members', 'list', workspace?.id],
        queryFn: ({ pageParam }) =>
            baseFetch<GetMembersResponse>(
                `/api/v1/members?${qs.stringify({ workspace_id: workspace?.id, offset: pageParam })}`,
                {
                    headers: getHeaders(),
                },
            ),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.pagination.remaining === 0 ? null : lastPage.pagination.offset),
    });

    const { data: invitedUsers } = useInvitedUsers();

    // Filter invited users based on workspace
    const filteredInvites = invitedUsers?.invited.filter(invite => {
        if (invite.workspaceId) return invite.workspaceId === workspace?.id;
        return false;
    });

    const suspend = useMemberSuspend();
    const reinstate = useMemberReinstate();
    const update = useMemberUpdate();

    /* Endpoint is sorted by suspension, then name so we can split instead juggling two queries */
    const fullData = data?.pages.flatMap(({ items }) => items) || [];
    const splitIdx = fullData.findIndex(({ member }) => member.suspended);
    const members = splitIdx > 0 ? fullData.slice(0, splitIdx) : fullData;
    const suspended = splitIdx > 0 ? fullData.slice(splitIdx) : [];

    const cbs = {
        changeRole: ({ role, memberId, username }: { role: CnslRoleType; memberId: string; username: string }) => {
            void update.mutateAsync({ role, memberId }).then(() => {
                if (username === user?.user?.username) {
                    const updatedUser = {
                        ...user!,
                        orgMemberships: user!.orgMemberships.map(m => {
                            if (m.orgId === user.orgMemberships[0].orgId) {
                                return { ...m, role };
                            }
                            return m;
                        }),
                    };
                    useUser.getState().setUser(updatedUser);
                }
            });
        },
        suspendMember: suspend.mutate,
        reinstateMember: reinstate.mutate,
    };

    return (
        <MembersList
            filteredInvites={filteredInvites}
            members={members}
            suspended={suspended}
            isAdmin={isAdmin}
            isFetching={isFetching}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isAddMemberModalOpen={isAddMemberModalOpen}
            setIsAddMemberModalOpen={setIsAddMemberModalOpen}
            cbs={cbs}
            currentWorkspaceId={workspace?.id}
        />
    );
};
