import { useUser } from '@/stores/useUser';
import { useMutation } from '@lib/hooks/useMutation';
import { useQuery } from '@lib/hooks/useQuery';
import type { DefaultError } from '@tanstack/react-query';
import type { OrgSetting } from 'lib/prisma/types';

type SupportedUpdateFields = Partial<Pick<OrgSetting, 'slackbot_testing_mode' | 'sandbox' | 'orgchart_provider'>>;

export const useOrgSettingsUpdate = () => {
    return useMutation<OrgSetting, DefaultError, SupportedUpdateFields>({
        url: `/api/v1/org-settings`,
        method: 'PATCH',
        successToast: {
            message: `Org setting updated`,
        },
    });
};

export const useOrgSettings = () => {
    const { workspace } = useUser();
    return useQuery<OrgSetting, DefaultError>({
        url: `/api/v1/org-settings`,
        queryKey: ['org-settings', workspace?.id ?? ''],
    });
};
