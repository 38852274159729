import type { GetIntegrationStatusResponse } from 'lib/models/integrations';

import { useQuery } from '@/lib/hooks/useQuery';

/**
 * Right now this only returns the status. We will extend it to return the entire settings object.
 */
export const useIntegrationSettings = () => {
    return useQuery<GetIntegrationStatusResponse>({
        url: '/api/v1/integrations/status',
    });
};

export const useOktaDomain = () => {
    return useQuery<{ domain: string }>({
        url: '/api/v1/integrations/okta/domain',
    });
};
