import { APPS_NEEDING_REVIEW } from '@/lib/apiRoutes';
import { useQuery } from '@/lib/hooks/useQuery';
import type {
    AppOwnersListItem,
    AppsWithPolicyCount,
    ProviderFilterOptionsResponse,
    SingleCnslApp,
} from 'lib/models/app';
import type { PaginatedListResponse } from 'lib/models/pagination';
import type { AccessRequestListItem } from 'lib/models/provisioning_requests';
import type { AppAuthTypeType } from 'lib/prisma/enums';

export const useApp = ({ slug, enabled }: { slug: string; enabled?: boolean }) => {
    return useQuery<{ app: SingleCnslApp | undefined }>({
        url: `/api/v1/apps/${slug}`,
        enabled,
    });
};

export const useAppProviderFilterOptions = () => {
    return useQuery<ProviderFilterOptionsResponse>({
        url: `/api/v1/apps/provider_filter_options`,
    });
};

export const useAppOwners = ({ slug }: { slug: string }, options?: { disabled: boolean }) => {
    return useQuery<PaginatedListResponse<AppOwnersListItem>>({
        url: `/api/v1/apps/${slug}/owners`,
        enabled: !options?.disabled && slug !== '',
        refetchOnMount: false,
    });
};

export const useAppsNeedingReview = () => {
    return useQuery<{ count: number }>({
        url: APPS_NEEDING_REVIEW,
    });
};

export const useAppAuthTypeCounts = ({ search }: { search?: string }) => {
    return useQuery<{ authTypes: Record<AppAuthTypeType, number> }>({
        url: `/api/v1/apps/_counts/auth-types?${search}`,
    });
};

export const useAppAccessRequests = ({ slug }: { slug: string }) => {
    return useQuery<PaginatedListResponse<AccessRequestListItem>>({
        url: `/api/v1/apps/${slug}/access-requests`,
    });
};

export const useGetAppsWithPolicyCounts = () => {
    return useQuery<{ apps: AppsWithPolicyCount[] }>({
        url: `/api/v1/apps/_counts/policies`,
    });
};
