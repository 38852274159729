import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import type { IntegrationId } from 'lib/integration';
import type { GetIntegrationStatusResponse } from 'lib/models/integrations';
import type { SyncSetting } from 'lib/models/sync';

import { useMutation } from '@/lib/hooks/useMutation';
import { useQuery } from '@/lib/hooks/useQuery';
import { getProviderAssets } from 'lib/3p';
import type { IntegrationErrorInterface } from 'lib/integration_settings/types';
import type { IntegrationResource } from 'lib/prisma/types';
import { toast } from 'sonner';

/**
 * Right now this only returns the status. We will extend it to return the entire settings object.
 */
export const useIntegrationSettingsStatus = () => {
    return useQuery<GetIntegrationStatusResponse>({
        url: '/api/v1/integrations/status',
    });
};

export const useIntegrationSettingsSync = () => {
    return useQuery<Record<string, SyncSetting>>({
        url: '/api/v1/integrations/sync-status',
        refetchInterval: 3000,
    });
};

export const useIntegrationSettingsError = () => {
    return useQuery<Record<string, IntegrationErrorInterface>>({
        url: '/api/v1/integrations/error-status',
        refetchInterval: 3000,
    });
};

export const useIntegrationSettingsUpdateLink = () => {
    return useQuery<Record<string, { updateLink: string | null }>>({
        url: '/api/v1/integrations/update-link',
        refetchInterval: 1000 * 60,
    });
};

export const useIntegrationSettingsResources = (provider: IntegrationId) => {
    return useQuery<{ resources: IntegrationResource[] }>({
        url: `/api/v1/integrations/${provider}/resources`,
    });
};

export const useIntegrationSettingsErrors = (provider: IntegrationId) => {
    return useQuery<{ errors: { title: string; description: string }[] }>({
        url: `/api/v1/integrations/${provider}/integration-errors`,
    });
};

export const useIntegrationSetupWebhooks = ({ provider }: { provider: IntegrationId }) => {
    return useMutation<{ success: boolean; message?: string }, DefaultError>({
        url: `/api/v1/integrations/${provider.toLowerCase()}/setup-webhooks`,
        method: 'POST',
        onSuccess: async ({ message }) => {
            toast.success(message ?? `${getProviderAssets(provider).name} configured successfully`);
        },
    });
};

export const useIntegrationReSync = ({ provider }: { provider: IntegrationId }) => {
    const queryClient = useQueryClient();
    return useMutation<{ ok: boolean }, DefaultError>({
        url: `/api/v1/integrations/sync/${provider}`,
        method: 'POST',
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: ['/api/v1/integrations/sync-status'], exact: true });
        },
        successToast: {
            message: 'Sync started',
        },
    });
};
