import { useQuery } from '@/lib/hooks/useQuery';
import type { DefaultPolicySettings, PolicyActionTemplateResponse, PolicyDetailsResponse } from 'lib/models/app_policy';
import type { CustomAppPolicyDuration } from 'lib/prisma/types';
import qs from 'qs';

export const useDefaultPolicyConfig = () => {
    return useQuery<{ settings: DefaultPolicySettings | undefined }>({
        url: `/api/v1/policies/default-settings`,
        queryKey: ['default-policy-settings'],
    });
};

export const usePolicyActionTemplates = (forApp?: { appSlug?: string; all?: boolean }) => {
    const query = forApp ? `?${qs.stringify(forApp)}` : '';
    return useQuery<PolicyActionTemplateResponse>({
        url: `/api/v1/policies/action-templates${query}`,
    });
};

export const usePolicyDetails = (policyId: string | undefined) => {
    return useQuery<PolicyDetailsResponse>({
        url: `/api/v1/policies/${policyId}`,
        enabled: !!policyId,
    });
};

export const usePolicyCustomDurationOptions = () => {
    return useQuery<CustomAppPolicyDuration[]>({
        url: `/api/v1/policies/custom-duration-options`,
        queryKey: ['custom-duration-options'],
    });
};

export const useAllPolicyActionTemplates = () => {
    return useQuery<PolicyActionTemplateResponse>({
        url: `/api/v1/policies/action-templates?all=true`,
    });
};
