import { Separator } from '@/components/ui/separator/separator';
import { locale } from '@/lib/locale';
import {
    useIntegrationSettingsErrors,
    useIntegrationSettingsResources,
} from '@hooks/queries/integrations/useIntegrationSettingsStatus';
import { getProviderAssets } from 'lib/3p';
import { IntegrationId } from 'lib/integration';
import { CloudDownload, TriangleAlert } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { SlackEnterpriseDetails } from './SlackEnterpriseDetails';

const formatDate = (date: Date) => {
    // Format the date part as MM/DD/YY
    return new Intl.DateTimeFormat(locale, {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    }).format(date);
};

export const ProviderDetails = () => {
    const { subsetting } = useParams() as { subsetting: IntegrationId };
    const { data } = useIntegrationSettingsResources(subsetting);
    const { data: errorData } = useIntegrationSettingsErrors(subsetting);
    const { logo, name } = getProviderAssets(subsetting);
    return (
        <div className="flex flex-col gap-md max-w-[640px]">
            <div className="flex items-center p-4">
                <img src={logo} alt={name} className="w-8 h-8 mr-4" />
                <h1 className="text-xl">{name}</h1>
            </div>
            {errorData?.errors.map((error, i) => (
                <div key={i} className="flex flex-col text-body-red-primary bg-bg-red-primary rounded-[6px] p-md">
                    <div className="flex items-center gap-sm">
                        <TriangleAlert />
                        <h3>{error.title}</h3>
                    </div>
                    <p>{error.description}</p>
                </div>
            ))}
            <Separator className="my-3" />
            <div className="flex flex-col gap-md">
                {subsetting === IntegrationId.Slack && <SlackEnterpriseDetails />}
                <div className="border-grey overflow-hidden rounded-md border-[0.5px]">
                    <div className="bg-bg-overlay p-md border-grey border-b-[0.5px] text-xs font-medium">
                        Integration Resources
                    </div>
                    <div className="flex flex-col gap-md">
                        {!data?.resources || data.resources.length === 0 ? (
                            <div className="w-full flex items-center justify-center text-center relative h-[100px] overflow-hidden">
                                <img
                                    src="/emptyStates/empty-circles.svg"
                                    alt=""
                                    className="absolute w-[280px] top-3/4 left-1/2 -translate-x-1/2 -translate-y-1/2 pointer-events-none"
                                    aria-hidden="true"
                                />
                                <p className="text-sm text-body-subtle z-10">
                                    This integration does not sync data to Console
                                </p>
                            </div>
                        ) : (
                            data.resources.map(resource => {
                                const errorString = [resource.auth_z_error, resource.misc_error]
                                    .filter(i => i)
                                    .join(', ');
                                return (
                                    <div
                                        key={resource.id}
                                        className="flex items-center justify-between border-b last:border-none border-subtle p-md"
                                    >
                                        <div className="flex flex-col justify-center flex-grow">
                                            <h5>{resource.display_name}</h5>
                                            {errorString && (
                                                <div className="flex gap-sm items-center text-[11px] text-body-red-primary">
                                                    <TriangleAlert className="size-3" />
                                                    <span className="truncate max-w-[400px]">{errorString}</span>
                                                </div>
                                            )}
                                            {!errorString && (
                                                <div className="text-xs text-body-subtle">
                                                    Created: {formatDate(new Date(resource.createdAt))}
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex items-center gap-sm text-body-subtle-hover text-xs">
                                            <CloudDownload className="size-3" />
                                            <p>Updated: {formatDate(new Date(resource.updatedAt))}</p>
                                        </div>
                                    </div>
                                );
                            })
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
