import type { PaginatedListResponse } from 'lib/models/pagination';

import { useQuery } from '@/lib/hooks/useQuery';
import type { ListUsersQPOptions } from 'lib/models/cnsl_user';
import type { Prisma } from 'lib/prisma/client';
import qs from 'qs';

const useUsers = (args?: { managerIds?: string[]; userIds?: string[]; search?: string; enabled: boolean }) => {
    const keys: string[] = [];
    if (args?.userIds) {
        keys.push('usersbyIds', ...args.userIds);
    }
    if (args?.managerIds) {
        keys.push('usersbyManagerIds', ...args.managerIds);
    }
    return useQuery<
        PaginatedListResponse<
            Prisma.cnsl_userGetPayload<{
                include: {
                    manager: true;
                };
            }>
        >
    >({
        url: `/api/v1/users?${qs.stringify({ limit: 1000, ids: args?.userIds, manager: args?.managerIds, search: args?.search })}`,
        queryKey: keys.length > 0 ? keys : undefined,
        enabled: args?.enabled,
        refetchOnMount: false,
    });
};

const useUsersQP = () => {
    return useQuery<ListUsersQPOptions>({
        url: `/api/v1/users/provider_qp_fields`,
    });
};

export { useUsers, useUsersQP };
