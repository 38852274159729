import { Tooltip, TooltipContent, TooltipTrigger } from '@ui/tooltip';
import { AlertTriangle } from 'lucide-react';

export const JiraTransitionWarning = ({
    fromStatuses,
    selected,
}: {
    fromStatuses: string[];
    selected: string | undefined;
}) => {
    if (!selected || !fromStatuses.length) return null;

    const formattedStatusList =
        fromStatuses.length > 1
            ? `${fromStatuses.slice(0, -1).join(', ')}, and ${fromStatuses[fromStatuses.length - 1]}`
            : fromStatuses[0];

    return (
        <Tooltip>
            <TooltipTrigger asChild>
                <div className="flex items-center gap-x-1 text-xs w-fit py-1">
                    <AlertTriangle className="size-3" />
                    <span className="underline">Status transition restrictions apply</span>
                </div>
            </TooltipTrigger>
            <TooltipContent side="bottom">
                <p>{`${selected} can only transition from: ${formattedStatusList}`}</p>
            </TooltipContent>
        </Tooltip>
    );
};
