import { Button } from '@/components/ui/button/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/styling';
import { SETTINGS_ORGANIZATION_INTEGRATION } from '@/routes/paths';
import type { IntegrationStatus } from 'lib/integration_settings/types';
import type { IntegrationSettingsSchema } from 'lib/models/integrations';
import { CircleCheck } from 'lucide-react';
import type { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import type * as v from 'valibot';
import type { IntegrationStep } from './ProviderForm';

export const StepsForm = ({
    form,
    steps,
    onSubmit,
    isPending,
    borderless,
    buttonSeparator,
    onCancel,
    status,
}: {
    form: UseFormReturn<v.InferInput<typeof IntegrationSettingsSchema>>;
    steps: IntegrationStep[];
    onSubmit: (values: v.InferInput<typeof IntegrationSettingsSchema>) => void;
    isPending: boolean;
    borderless?: boolean;
    buttonSeparator?: boolean;
    onCancel?: () => void;
    status?: IntegrationStatus;
}) => {
    const navigate = useNavigate();
    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="">
                {steps.map((s, i) => {
                    const isFieldFilled = s.field && status?.requiredSettings[s.field];
                    return (
                        <div
                            key={s.field || i}
                            className={cn(
                                'p-lg',
                                i === 0 ? 'rounded-t-md' : '',
                                borderless ? '' : 'border-t-[0.5px] border-r-[0.5px] border-l-[0.5px] border-grey',
                            )}
                        >
                            <div className="flex items-center gap-2">
                                <FormLabel>{`${i + 1}. ${s.title}`}</FormLabel>{' '}
                            </div>
                            <div className="py-2">
                                {s.component ? (
                                    isFieldFilled ? (
                                        <div className="h-10 flex items-center px-3 text-black">
                                            <span className="font-semibold">Connected!</span>
                                            <CircleCheck className="size-4 text-green-900 ml-2" />
                                        </div>
                                    ) : (
                                        <s.component />
                                    )
                                ) : s.field ? (
                                    <FormField
                                        control={form.control}
                                        name={
                                            `data.${s.field}` as `data.${keyof v.InferInput<typeof IntegrationSettingsSchema>['data']}`
                                        }
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    {isFieldFilled ? (
                                                        <div className="h-10 flex items-center px-3 text-black">
                                                            <span>••••••••</span>
                                                            <CircleCheck className="size-4 text-green-900 ml-2" />
                                                        </div>
                                                    ) : (
                                                        <Input placeholder={s.placeholder} {...field} />
                                                    )}
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                ) : null}
                            </div>
                            {s.description && <FormDescription>{s.description}</FormDescription>}
                        </div>
                    );
                })}
                <div
                    className={cn(
                        'flex items-center justify-between w-full p-lg rounded-b-md',
                        borderless ? '' : 'border-[0.5px] border-grey',
                        buttonSeparator ? 'border-t-[0.5px] border-grey' : '',
                    )}
                >
                    <Button
                        mode="borderless"
                        onClick={onCancel || (() => navigate(SETTINGS_ORGANIZATION_INTEGRATION))}
                        disabled={isPending}
                    >
                        Cancel
                    </Button>
                    <Button variant="blue" mode="dark" type="submit" disabled={isPending}>
                        {isPending ? 'Verifying Credentials...' : 'Submit'}
                    </Button>
                </div>
            </form>
        </Form>
    );
};
