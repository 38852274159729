// root routes
export const ROOT = '/';

export const LOGIN = '/login';
export const SIGNUP = '/signup';
export const SETUP = '/setup';
export const SETUP_STEP = `${SETUP}/:step`;
export const SETUP_STEP_ORG = `${SETUP}/org`;

export const INITIALIZE_ORG = '/initialize/:token';

export const ONBOARDING = '/';
export const ONBOARDING_SELF_SERVICE = `/onboarding/:step`;

export const ROI_ROOT = '/roi';
export const ROI = `${ROI_ROOT}/:orgId`;

// we don't define all settings routes here because they are derived dynamically on settings page
export const SETTINGS = 'settings';
export const SETTINGS_ORGANIZATION = `${SETTINGS}/organization`;
export const SETTINGS_WORKSPACE = `${SETTINGS}/workspace`;
export const SETTINGS_ORGANIZATION_INTEGRATION = `${SETTINGS_ORGANIZATION}/integrations`;
export const SETTINGS_ORGANIZATION_MEMBERS = `${SETTINGS_ORGANIZATION}/members`;
export const SETTINGS_ORGANIZATION_WORKSPACES = `${SETTINGS_ORGANIZATION}/workspaces`;
export const SETTINGS_ORGANIZATION_GENERAL = `${SETTINGS_ORGANIZATION}/general`;
export const SETTINGS_WORKSPACE_GENERAL = `${SETTINGS_WORKSPACE}/general`;
export const SETTINGS_WORKSPACE_MEMBERS = `${SETTINGS_WORKSPACE}/members`;
export const SETTINGS_WORKSPACE_HELP_DESK = `${SETTINGS_WORKSPACE}/help-desk-configuration`;
export const SETTINGS_WORKSPACE_CATEGORIES = `${SETTINGS_WORKSPACE}/categories`;
export const SETTINGS_ACCOUNT = `${SETTINGS}/account`;
export const SETTINGS_REQUESTS = `${SETTINGS}/requests`;

// oauth callbacks
export const OKTA_CALLBACK = '/auth/login/okta/callback';
export const GOOGLE_OAUTH_CALLBACK = '/oauth/google/callback';
export const OKTA_OAUTH_CALLBACK = '/oauth/okta/callback';
export const SLACK_CALLBACK = '/oauth/slack/callback';
export const NOTION_OAUTH_CALLBACK = '/oauth/notion/callback';
export const JIRA_OAUTH_CALLBACK = '/oauth/jira/callback';

export const AGENTS = 'agents';
export const AGENT_DETAILS = `${AGENTS}/:slug`;

export const APPS = 'apps';
export const APP_DETAILS = `${APPS}/:slug`;

export const USERS = 'users';
export const USER_DETAILS = `${USERS}/:slug`;

export const EVENTS = 'events';

export const GROUPS = 'groups';
export const GROUP_DETAILS = `${GROUPS}/:slug`;

// New workspace-aware routes
export const REQUESTS = 'requests';
export const REQUESTS_DETAILS = `${REQUESTS}/:slug`;

export const PLAYBOOKS = 'playbooks';
export const PLAYBOOK_DETAILS = `${PLAYBOOKS}/:slug/:version?`;
export type PLAYBOOK_DETAILS_TYPE = `${typeof PLAYBOOKS}/${string}`;

export const ACTIONS = 'playbooks/actions';
export const ACTIONS_CREATE = `${ACTIONS}/new`;
export const ACTIONS_DETAILS = `${ACTIONS}/:provider/:slug`;
export type ACTIONS_DETAILS_TYPE = `${typeof ACTIONS}/${string}/${string}`;

export const PLAYBOOK_TEMPLATES = 'playbooks/templates';

export const ARTICLES = 'articles';
export const ARTICLE_DETAILS = `${ARTICLES}/:slug/:version?`;
export type ARTICLE_DETAILS_TYPE = `${typeof ARTICLES}/${string}`;

export const POLICIES = 'policies';
export const POLICY_CREATE = `${POLICIES}/new`;
export const POLICY_DETAILS = `${POLICIES}/:policyId`;
export const POLICY_DETAILS_APP = `${POLICIES}/_apps/:slug/:tab`;
export const POLICY_DETAILS_V2 = `${POLICIES}/_apps/:slug/policies/:policyId`;
export const ACCESS_REQUESTS = `access-requests`;

export const INSIGHTS = 'insights';

export const SNIPPETS = 'snippets';
export const SNIPPET_DETAILS = `${SNIPPETS}/:id`;

export const TICKETS = 'external/tickets';
export const TICKET_DETAILS = `${TICKETS}/:id`;

export const ROOT_TO_WORKSPACE_REDIRECT_PATHS = [
    APPS,
    SNIPPETS,
    SNIPPET_DETAILS,
    AGENTS,
    AGENT_DETAILS,
    EVENTS,
    GROUPS,
    GROUP_DETAILS,
    ACTIONS,
    ACTIONS_DETAILS,
    ACTIONS_CREATE,
    PLAYBOOKS,
    PLAYBOOK_DETAILS,
    PLAYBOOK_TEMPLATES,
    POLICIES,
    POLICY_DETAILS,
    POLICY_DETAILS_APP,
    POLICY_DETAILS_V2,
    POLICY_CREATE,
    ARTICLES,
    ARTICLE_DETAILS,
    USERS,
    `${USER_DETAILS}/*`,
    `${APP_DETAILS}/*`,
    REQUESTS,
    REQUESTS_DETAILS,
    INSIGHTS,
    TICKETS,
    TICKET_DETAILS,
];
