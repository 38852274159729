import { Button } from '@/components/ui/button/button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Label } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useWorkspaceDelete, useWorkspaceUpdate } from '@/hooks/mutations/useWorkspaces';
import { useEffect, useState } from 'react';

interface ManageWorkspaceModalProps {
    isOpen: boolean;
    onClose: () => void;
    onDelete: () => void;
    workspace: {
        id: string;
        name: string;
        identifier: string;
        is_default: boolean;
    } | null;
}

export function ManageWorkspaceModal({ isOpen, onClose, onDelete, workspace }: ManageWorkspaceModalProps) {
    const [name, setName] = useState('');
    const [identifier, setIdentifier] = useState('');
    const { mutateAsync: updateWorkspace, isPending } = useWorkspaceUpdate(workspace?.id || '');
    const { mutate: deleteWorkspace } = useWorkspaceDelete(workspace?.id || '');

    const handleDelete = () => {
        if (confirm('Are you sure you want to delete this workspace?')) {
            deleteWorkspace(undefined, {
                onSuccess: () => {
                    onDelete();
                    onClose();
                },
            });
        }
    };

    useEffect(() => {
        if (workspace) {
            setName(workspace.name);
            setIdentifier(workspace.identifier);
        }
    }, [workspace]);

    const hasChanges = workspace && (name !== workspace.name || identifier !== workspace.identifier);

    const handleSave = async () => {
        if (!workspace || !hasChanges) return;

        await updateWorkspace({
            name: name !== workspace.name ? name : undefined,
            identifier: identifier !== workspace.identifier ? identifier : undefined,
        });
    };

    if (!workspace) return null;

    return (
        <Dialog
            open={isOpen}
            onOpenChange={open => {
                if (!open) {
                    onClose();
                }
            }}
        >
            <DialogContent className="max-w-md">
                <DialogHeader>
                    <DialogTitle>Manage Workspace</DialogTitle>
                </DialogHeader>
                <div className="p-lg space-y-4">
                    <div className="pb-4">
                        <Label htmlFor="workspaceName" className="text-grey font-semibold">
                            Workspace Name
                        </Label>
                        <div className="flex items-center gap-2 mt-1">
                            <Input
                                id="workspaceName"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                className="flex-1"
                            />
                        </div>
                    </div>

                    <div className="pb-4">
                        <Label htmlFor="workspaceIdentifier" className="text-grey font-semibold">
                            Workspace Identifier
                        </Label>
                        <div className="flex items-center gap-2 mt-1">
                            <Input
                                id="workspaceIdentifier"
                                value={identifier}
                                onChange={e => setIdentifier(e.target.value.toLowerCase().replace(/[^a-z0-9]/g, ''))}
                                className="flex-1"
                                maxLength={16}
                            />
                        </div>
                        {identifier !== workspace.identifier && (
                            <p className="text-body-subtle text-xs italic mt-1">
                                Warning: Changing the identifier will break existing links to request URLs for this
                                workspace.
                            </p>
                        )}
                    </div>

                    {!workspace.is_default && (
                        <div>
                            <Label className="text-grey font-semibold">Danger Zone</Label>
                            <Button
                                mode="light"
                                variant="red"
                                onClick={() => handleDelete()}
                                className="text-red-900 hover:bg-red-50 flex items-center gap-sm w-full justify-center mt-1"
                            >
                                Delete Workspace
                            </Button>
                        </div>
                    )}
                </div>
                <DialogFooter>
                    <div className="flex justify-end gap-md grow">
                        {hasChanges && (
                            <Button mode="light" variant="blue" onClick={handleSave} disabled={isPending}>
                                Save Changes
                            </Button>
                        )}
                        <Button mode="light" onClick={onClose}>
                            Close
                        </Button>
                    </div>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
