import { useQuery } from '@/lib/hooks/useQuery';
import { useUser } from '@/stores/useUser';
import { IntegrationId } from 'lib/integration';
import type { IssueType } from 'lib/jira/types';
import { isApiError } from 'lib/models/error';
import {
    type NamespaceOptions,
    TicketInProgressStatusSettingKey,
    type TicketProvisioningOptionsSerialized,
    TicketProvisioningStatusSettingKey,
} from 'lib/models/help_desk';
import { type CnslTicketTypeType, TicketProvider, type TicketProviderType } from 'lib/prisma/enums';
import type { HelpDeskConfig, HelpDeskFieldConfig } from 'lib/prisma/types';
import { useMemo } from 'react';
import { useIntegrationSettingsStatus } from './integrations/useIntegrationSettingsStatus';
import { useSettingRequest } from './useSettingRequest';

export const useHelpDeskConfigs = () => {
    const { workspace } = useUser();
    return useQuery<{ configs: HelpDeskConfig[] }>({
        url: `/api/v1/help-desk/configs`,
        queryKey: ['help-desk-configs', workspace?.id ?? ''],
    });
};

export const useHelpDeskFields = (provider: IntegrationId, namespace: string, ticketType: CnslTicketTypeType) => {
    const { workspace } = useUser();
    return useQuery<{ fields: HelpDeskFieldConfig[] }>({
        url: `/api/v1/help-desk/fields?provider=${provider}&namespace=${namespace}&cnslTicketType=${ticketType}`,
        queryKey: ['help-desk-fields', workspace?.id ?? '', provider, namespace, ticketType],
    });
};

export const useSeamlessHandoffSettings = () => {
    const { workspace } = useUser();
    return useQuery<{ enabled: boolean }>({
        url: `/api/v1/help-desk/seamless-handoff-settings`,
        queryKey: ['seamless-handoff-settings', workspace?.id ?? ''],
    });
};

export const useNamespaceOptions = () => {
    return useQuery<NamespaceOptions>({
        url: '/api/v1/help-desk/namespace-options',
    });
};

export const useHelpDeskProvidersStatus = () => {
    const helpDeskProviders = Object.values(TicketProvider);

    const { data } = useIntegrationSettingsStatus();
    const statusData = data?.status ?? {};

    const enabledHelpdesks = useMemo(() => {
        return helpDeskProviders.map(provider => ({
            provider,
            enabled: statusData[provider]?.enabled ?? false,
        }));
    }, [statusData, helpDeskProviders]);

    return enabledHelpdesks;
};

export const useJiraIssueTypes = (namespace: string): IssueType[] => {
    const { data } = useSettingRequest(IntegrationId.Jira, [namespace]);
    if (isApiError(data)) {
        return [];
    }
    return data?.ticketTypes ?? [];
};

export const useTicketProvisioning = (type: CnslTicketTypeType, provider: TicketProviderType, namespace?: string) => {
    const params = new URLSearchParams();
    params.set('provider', provider);
    if (namespace) {
        params.set('namespace', namespace);
    }
    return useQuery<TicketProvisioningOptionsSerialized>({
        url: `/api/v1/help-desk/ticket-provisioning-options/${type}?${params.toString()}`,
        queryKey: [TicketProvisioningStatusSettingKey, type, provider, namespace ?? ''],
    });
};

export const useTicketInProgressStatus = (
    type: CnslTicketTypeType,
    provider: TicketProviderType,
    namespace?: string,
) => {
    const params = new URLSearchParams();
    params.set('provider', provider);
    if (namespace) {
        params.set('namespace', namespace);
    }
    const data = useQuery<TicketProvisioningOptionsSerialized>({
        url: `/api/v1/help-desk/ticket-in-progress-status/${type}?${params.toString()}`,
        queryKey: [TicketInProgressStatusSettingKey, type, provider, namespace ?? ''],
    });
    return data;
};
