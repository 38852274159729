import { valibotResolver } from '@hookform/resolvers/valibot';
import { useMergeLink } from '@mergeapi/react-merge-link';
import { NangoIntegrations, getProviderAssets } from 'lib/3p';
import { type IntegrationIdValues, MergeIntegrationId } from 'lib/integration';
import { IntegrationId } from 'lib/integration';
import { integrationDefaults, settingLabels } from 'lib/integration_settings/default';
import { IntegrationSettingsSchema } from 'lib/models/integrations';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import type * as v from 'valibot';

import { ResLink } from '@/components/ResLink';
import { Button } from '@/components/ui/button/button';
import { Form } from '@/components/ui/form';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form/form';
import { Input } from '@/components/ui/input';
import { Separator } from '@/components/ui/separator/separator';
import { useIntegrationSettingPost } from '@/hooks/mutations/integrations/useIntegrationSettingPost';
import { useIntegrationSettingsStatus } from '@/hooks/queries/integrations/useIntegrationSettingsStatus';
import { useConfluenceGetOAuthUrl } from '@/hooks/queries/useConfluence';
import { useMergeGetMergeToken, useMergeSendPublicToken } from '@/hooks/queries/useMerge';
import { useGoogleGetOAuthUrl, useOktaGetOAuthUrl } from '@/hooks/queries/useOAuth';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { NotFoundPage } from '@/pages/errors/404';
import { SETTINGS_ORGANIZATION_INTEGRATION } from '@/routes/paths';
import { FlagKey } from 'lib/flags/keys';
import { NangoConnect } from './NangoConnect';
import { StepsForm } from './StepsForm';

type UnionKeys<T> = T extends T ? keyof T : never;
type IntegrationFields = UnionKeys<v.InferInput<typeof IntegrationSettingsSchema>['data']>;

export interface IntegrationStep {
    title: string;
    field: IntegrationFields;
    description?: string;
    placeholder?: string;
    component?: React.ComponentType;
}

const _steps: Record<string, IntegrationStep[]> = {
    freshservice: [
        {
            title: 'Freshservice subdomain',
            field: 'subdomain',
            description: 'Enter your Freshservice subdomain',
            placeholder: 'example.freshservice.com',
        },
        {
            title: 'Freshservice ingestion key',
            field: 'ingestionKey',
            description: 'Enter your Freshservice ingestion key',
            placeholder: 'ingestion key',
        },
    ],
    zendesk: [
        {
            title: 'Zendesk subdomain',
            field: 'subdomain',
            description: 'Enter your Zendesk subdomain',
            placeholder: 'example.zendesk.com',
        },
        {
            title: 'Zendesk ingestion key',
            field: 'ingestionKey',
            description: 'Enter your Zendesk ingestion key',
            placeholder: 'ingestion key',
        },
        {
            title: 'Zendesk service account email',
            field: 'serviceAccountEmail',
            description: 'Enter your Zendesk service account email',
            placeholder: 'service account email',
        },
    ],
    okta: [
        {
            title: 'Okta domain',
            field: 'oauthDomain',
            description: 'Enter your Okta domain',
            placeholder: 'https://example.okta.com',
        },
        {
            title: 'Okta client Id',
            field: 'integrationClientId',
            description: 'Enter your Okta client id',
            placeholder: 'client id',
        },
        {
            title: 'Okta client secret',
            field: 'integrationClientSecret',
            description: 'Enter your Okta client secret',
            placeholder: 'client secret',
        },
    ],
    'snipe-it': [
        {
            title: 'Snipe-IT domain',
            field: 'domain',
            description: 'Enter your Snipe-IT domain',
            placeholder: 'example.snipe-it.io',
        },
        {
            title: 'Snipe-IT API key',
            field: 'apiKey',
            description: 'Enter your Snipe-IT API key',
            placeholder: 'api key',
        },
    ],
    airtable: [
        {
            title: 'Connect Airtable',
            field: 'nangoConnectionId',
            description: 'Connect your Airtable account to Console',
            component: () => <NangoConnect provider={IntegrationId.Airtable} />,
        },
        {
            title: 'Airtable Enterprise Account ID',
            field: 'enterpriseAccountId',
            description: 'Enter your root Airtable Enterprise Account ID',
            placeholder: 'enterprise account id',
        },
    ],
};

const MergeProviderInternal = ({ linkToken, provider }: { linkToken: string; provider: MergeIntegrationId }) => {
    const navigate = useNavigate();
    const sendPublicToken = useMergeSendPublicToken(provider);
    const onSuccess = useCallback(
        async (publicToken: string) => {
            await sendPublicToken.mutate({ token: publicToken });
            navigate(SETTINGS_ORGANIZATION_INTEGRATION);
        },
        [navigate, sendPublicToken],
    );

    const { open, isReady } = useMergeLink({
        linkToken,
        onSuccess,
    });

    return (
        <Button disabled={!isReady} variant="blue" mode="dark" onClick={open}>
            Connect Integration
        </Button>
    );
};

const MergeProviderButton = ({ provider }: { provider: MergeIntegrationId }) => {
    const { data } = useMergeGetMergeToken(provider);
    if (!data) {
        return <NotFoundPage />;
    }
    return <div>{data && <MergeProviderInternal linkToken={data.linkToken} provider={provider} />}</div>;
};

const GoogleProviderButton = () => {
    const { data } = useGoogleGetOAuthUrl();
    return (
        <Button>
            <a href={data?.url} target="_blank" rel="noreferrer" className="size-full flex items-center justify-center">
                Connect Google
            </a>
        </Button>
    );
};

const ConfluenceProviderButton = () => {
    const { data } = useConfluenceGetOAuthUrl();
    return (
        <Button>
            <a href={data?.url} target="_blank" rel="noreferrer" className="size-full flex items-center justify-center">
                Connect Confluence
            </a>
        </Button>
    );
};

const OktaProviderButton = () => {
    const { data } = useOktaGetOAuthUrl();
    return (
        <Button>
            <a href={data?.url} target="_blank" rel="noreferrer" className="size-full flex items-center justify-center">
                Connect Okta
            </a>
        </Button>
    );
};

const oktaDefaults = {
    oauthDomain: undefined as string | undefined,
    integrationClientId: undefined as string | undefined,
    integrationClientSecret: undefined as string | undefined,
    COOL: undefined as string | undefined,
};

const CustomProviderForm = ({ provider }: { provider: IntegrationIdValues }) => {
    const mutation = useIntegrationSettingPost({ integrationCreated: true });
    const navigate = useNavigate();
    const { data: statusData } = useIntegrationSettingsStatus();

    const defaultValues = {
        provider,
        data: provider === IntegrationId.Okta ? oktaDefaults : integrationDefaults[provider],
    };

    const form = useForm<v.InferInput<typeof IntegrationSettingsSchema>>({
        resolver: valibotResolver(IntegrationSettingsSchema),
        // @ts-expect-error - fix
        defaultValues,
    });

    async function onSubmit(values: v.InferInput<typeof IntegrationSettingsSchema>) {
        await mutation.mutateAsync({ ...values });
        navigate(SETTINGS_ORGANIZATION_INTEGRATION);
    }

    const fields = Object.keys(defaultValues.data).filter(
        i => i !== 'sync' && i !== 'errors',
    ) as (keyof typeof settingLabels)[];

    const { name, logo } = getProviderAssets(provider);
    const steps = { ..._steps };
    const oktaServiceIntegration = useFeatureFlag(FlagKey.OktaAPIServiceIntegration);
    if (!oktaServiceIntegration) {
        delete steps.okta;
    }

    const showNangoConnect = NangoIntegrations.has(provider as IntegrationId);

    return (
        <div className="gap-2xl flex flex-col">
            <div className="flex flex-col gap-md">
                <div className="flex items-center gap-md">
                    <ResLink rounded="square" avatarOnly src={logo} label={`${provider} logo`} />
                    <h1 className="text-xl capitalize">{`${name} integration`}</h1>
                </div>
                <p className="text-body-subtle-hover text-base">{`Connect ${name} to see your data in Console`}</p>
            </div>
            <Separator />
            <div className="flex flex-col gap-lg">
                <h3 className="text-base">Instruction</h3>
                {steps[provider] ? (
                    <StepsForm
                        isPending={mutation.isPending}
                        form={form}
                        steps={steps[provider] as IntegrationStep[]}
                        onSubmit={onSubmit}
                        status={statusData?.status[provider]}
                    />
                ) : showNangoConnect ? (
                    <NangoConnect provider={provider as IntegrationId} />
                ) : provider === IntegrationId.Google ? (
                    <GoogleProviderButton />
                ) : provider === IntegrationId.Okta && !oktaServiceIntegration ? (
                    <OktaProviderButton />
                ) : provider === IntegrationId.Confluence ? (
                    <ConfluenceProviderButton />
                ) : (
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                            {fields.map(f => (
                                <FormField
                                    control={form.control}
                                    key={f}
                                    name={`data.${f}`}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>{settingLabels[f]}</FormLabel>
                                            <FormControl>
                                                <Input {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            ))}
                            <div className="flex items-center justify-between w-full">
                                <Button
                                    type="button"
                                    onClick={() => navigate(SETTINGS_ORGANIZATION_INTEGRATION)}
                                    disabled={mutation.isPending}
                                >
                                    Cancel
                                </Button>
                                <Button variant="blue" mode="dark" type="submit" disabled={mutation.isPending}>
                                    {mutation.isPending ? 'Verifying Credentials...' : 'Submit'}
                                </Button>
                            </div>
                        </form>
                    </Form>
                )}
            </div>
        </div>
    );
};

const ProviderForm = ({ provider }: { provider: IntegrationIdValues }) => {
    if (Object.values(MergeIntegrationId).includes(provider as MergeIntegrationId)) {
        return <MergeProviderButton provider={provider as MergeIntegrationId} />;
    }

    return <CustomProviderForm provider={provider} />;
};

export default ProviderForm;
