import type { Setting } from '@pages/settings/types/Settings';
import { formatPathname } from '@pages/settings/utils';
import { Link } from 'react-router-dom';

import { cn } from '@/lib/styling';
import { getOrgMembership } from '@/stores/useUser';
import { useUser } from '@/stores/useUser';

interface GroupProps extends Setting {
    activeRoute?: string;
    basePath: string;
}

const Group = ({ icon: Icon, label, pathname, children, activeRoute, basePath }: GroupProps) => {
    const { user } = useUser();
    const nerfed = getOrgMembership(user)?.nerfed;

    return (
        <div className="pt-lg first:pt-0">
            <div className="gap-md px-md text-body-subtle py-sm flex items-center text-xs">
                {Icon && <Icon className="size-3.5" />}
                <p>{label}</p>
            </div>
            <div className="gap-[2px] pl-[22px] flex flex-col">
                {children?.map((setting, i) => {
                    if (setting.hide) {
                        return null;
                    }

                    const tail = setting.pathname;
                    const formattedPathname = formatPathname(basePath, pathname || '', tail);
                    const isSelected = `${basePath}${activeRoute}` === formattedPathname;
                    const isDisabled = setting.disabledIfNerfed && nerfed;

                    const handleDisabledClick = (e: React.MouseEvent) => {
                        e.preventDefault();
                        setting.onDisabledClick?.();
                    };

                    return (
                        <Link
                            key={i}
                            to={formattedPathname}
                            className={cn(
                                'px-md py-sm h-7 w-full hover:bg-bg-grey-primary hover:text-body-grey-primary-hover rounded-sm flex items-center',
                                isSelected ? 'bg-bg-grey-primary text-body-grey-primary-hover' : undefined,
                                isDisabled && 'opacity-50',
                            )}
                            onClick={isDisabled ? handleDisabledClick : undefined}
                        >
                            {setting.label}
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default Group;
