import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button/button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Select } from '@/components/ui/select';
import { useDeleteInvite } from '@/hooks/mutations/useDeleteInvite';
import { useWorkspaceRemoveMember } from '@/hooks/mutations/useWorkspaces';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { initials } from '@/lib/initials';
import { cn } from '@/lib/styling';
import { FlagKey } from 'lib/flags/keys';
import type {
    GetMembersResponse,
    SchemaSuspendMemberType,
    SchemaUnsuspendMemberType,
    SchemaUpdateMemberType,
} from 'lib/models/members';
import type { InvitedUser } from 'lib/prisma/types';
import { Ellipsis, Trash2, UserMinus, UserRoundPlus, UserRoundX } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { memberOptions } from './constants';
import { CreateWorkspaceModal } from './modals';

type MemberRowProps = GetMembersResponse['items'][number] & {
    disabled: boolean;
    changeRole: (_: SchemaUpdateMemberType & { username: string }) => void;
    suspendMember: (_: SchemaSuspendMemberType) => void;
    reinstateMember: (_: SchemaUnsuspendMemberType) => void;
    currentWorkspaceId?: string;
};

export function MemberRow({
    member,
    cnslUser,
    changeRole,
    suspendMember,
    reinstateMember,
    disabled,
    currentWorkspaceId,
}: MemberRowProps) {
    const isWorkspacesEnabled = useFeatureFlag(FlagKey.Workspaces);
    const [isAddWorkspaceModalOpen, setIsAddWorkspaceModalOpen] = useState(false);
    const name = cnslUser?.displayName || member.user.username;
    const email = cnslUser?.email || member.user.username;
    const i = initials(name || '');
    const hasCnslAccount = cnslUser?.email;

    const { mutate: removeFromWorkspace } = useWorkspaceRemoveMember(currentWorkspaceId || '', member.id);

    const handleRemoveFromWorkspace = () => {
        if (currentWorkspaceId) {
            removeFromWorkspace();
        }
    };

    const avatar = (
        <div className={cn('gap-md flex items-center !cursor-pointer', { 'opacity-50': disabled })}>
            <Avatar size="lg">
                <AvatarImage src={cnslUser?.avatar || undefined} alt={i} />
                <AvatarFallback>{i}</AvatarFallback>
            </Avatar>
            <div className="flex flex-col justify-center h-8">
                <div className="font-medium">{name}</div>
                <span className="text-body-subtle text-xs mt-[-2px]">{email}</span>
            </div>
        </div>
    );

    return (
        <>
            <div className="p-md border-subtle flex items-center justify-between border-b last:border-none">
                {hasCnslAccount ? <Link to={`/users/${email}`}>{avatar}</Link> : avatar}
                <div className="gap-md flex items-center">
                    {!member.suspended && !currentWorkspaceId && !disabled && (
                        <Select
                            isDisabled={disabled}
                            trigger="default"
                            value={memberOptions.find(({ value }) => value === member.role)}
                            options={memberOptions}
                            onChange={option => {
                                if (option) {
                                    changeRole({
                                        role: option.value,
                                        memberId: member.id,
                                        username: member.user.username,
                                    });
                                }
                            }}
                        />
                    )}
                    {member.suspended && <p className="text-body-subtle">Suspended</p>}
                    {!disabled && (
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button mode="borderless">
                                    <Ellipsis />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent side="bottom" align="end">
                                {!currentWorkspaceId && (
                                    <DropdownMenuItem
                                        onClick={() => {
                                            member.suspended
                                                ? reinstateMember({ memberId: member.id })
                                                : suspendMember({ memberId: member.id });
                                        }}
                                    >
                                        <div className={cn('gap-sm flex', !member.suspended && 'text-red-900')}>
                                            {member.suspended ? (
                                                <>
                                                    <UserRoundPlus />
                                                    Reinvite Member
                                                </>
                                            ) : (
                                                <>
                                                    <UserRoundX />
                                                    Suspend Member
                                                </>
                                            )}
                                        </div>
                                    </DropdownMenuItem>
                                )}
                                {isWorkspacesEnabled && currentWorkspaceId && (
                                    <DropdownMenuItem onClick={handleRemoveFromWorkspace}>
                                        <div className="gap-sm flex">
                                            <UserMinus />
                                            Remove from Workspace
                                        </div>
                                    </DropdownMenuItem>
                                )}
                            </DropdownMenuContent>
                        </DropdownMenu>
                    )}
                </div>
            </div>
            <CreateWorkspaceModal isOpen={isAddWorkspaceModalOpen} onClose={() => setIsAddWorkspaceModalOpen(false)} />
        </>
    );
}

interface InvitedMemberRowProps {
    invite: InvitedUser;
}

export const InvitedMemberRow = ({ invite }: InvitedMemberRowProps) => {
    const deleteInviteMutation = useDeleteInvite(invite.id);

    return (
        <div className="p-md border-subtle flex items-center justify-between border-b last:border-none">
            <div className="flex flex-col">
                <div className="font-medium">{invite.email}</div>
            </div>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button mode="borderless">
                        <Ellipsis />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent side="bottom" align="end">
                    <DropdownMenuItem onClick={() => deleteInviteMutation.mutate({ id: invite.id })}>
                        <div className="gap-sm flex text-red-900">
                            <Trash2 size={16} />
                            Delete Invite
                        </div>
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );
};
