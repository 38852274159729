import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { RowData } from '@tanstack/react-table';
import { TooltipProvider } from '@ui/tooltip.tsx';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import ReactDOM from 'react-dom/client';

import './instrumentation';

/* Need this directly imported bc FE ts dropping transitive namespace */
import 'lib/prisma/namespaceTypes';
/* Customize the ColumnMeta on the react-table */
import '@tanstack/react-table';

import RegisterGlobalListeners from './components/RegisterGlobalListeners.tsx';
import Routes from './routes/index.tsx';
import { useUser } from './stores/useUser.tsx';

import './index.css';

declare module '@tanstack/react-table' {
    // biome-ignore lint/correctness/noUnusedVariables: <explanation>
    interface ColumnMeta<TData extends RowData, TValue> {
        expand?: boolean;
    }
}

const asciiArt = `                                                                                          
                                                                                          
                                                                                          
                                                                                          
                                                                                          
                                                                                          
                                                                                          
                                           .:::.                                          
                                        .++--+*#*-                                        
                                       -+:..-=+**#*:                                      
                                     :*=::::==++***#+                                     
                                   .+=...:.----++**+*#-                                   
                                  .*::::..-=::-*+++=+*#=                                  
                                 -*-::..:--:--:***++***#+                                 
                               =*%*-:.::---====+****++*#%%*.                              
                             .+-.-#+-::-=====:-==++***#%#**#=                             
                            =+:-:::--================+++****#*.                           
                          :*-::-::-+**##*+=======+**#*+=+*+=**#=                          
                         =+:::::-=+*.-+=#%+====+#-:+=*%+++++***##-                        
                        -%:::::==++%#%+-#%+====+%#%%=*%*+++====+*%                        
                        =#-+++:=+++*%%%%%*+*++*++#%%%%#++++=+=-+#%.                       
                        =#:---:++++==+++++++**+++++++===++++=:=-#%.                       
                        *#-==+:+++====++++++++++++++==-=++==+++**%:                       
                      .*%#--=+---:-:+++++++++++++=+++-:.=++==++**%#-                      
                     .#*%%===--++=.=+===+++++-=++=++++-=+++==+**#%%#=                     
                     =*#--#=-::+++.++=-=+++++:=+++++++-=+++++**#*:*+#                     
                     ++*  :**+++=-.=++:::--=+++++---++-+:+++**#=  =*#:                    
                    .***=   -#*+=--=++=--++=-==-+--:++=-=+**#+.   ***#                    
                     +++##:   +#*++++-:=-+++++-:-+.++--=**#*:   +*=+**                    
                     +**#*.    .=#*+====++=+++--=*.+*==+##=     =****:                    
                       .         .+######**#######=*###*-         ..                      
                                    .*%%+........:%%%:                                    
                                     +##=         %#%.                                    
                                     ++*=         %+#.                                    
                                     ++*=        .%+#.                                    
                                    #+**##      :***##-                                   
                                  ==++++*%      :+=+***+:                                 
                           ......+=++++*#%......-+++***##.......                          
                      ..:::::::::-*******+:::::::+******+:::::::::..                      
                                    ...................                                   
                                                                                          
                                                                                          
                                                                                          
                                                                                          
                                                                                          
`;

console.info(`%c ${asciiArt}`, 'color: #0086AA');

export const queryClient = new QueryClient();

/**
 * A wrapper around QueryClientProvider that uses the workspace ID as a key
 * to force the QueryClient to remount when the workspace changes.
 * This ensures that all queries are refetched when switching workspaces.
 */
const WorkspaceAwareQueryProvider = ({ children }: { children: React.ReactNode }) => {
    const { workspace } = useUser();
    return (
        <QueryClientProvider client={queryClient} key={workspace?.id || 'default'}>
            {children}
        </QueryClientProvider>
    );
};

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <PostHogProvider
            apiKey={import.meta.env.VITE_POSTHOG_API_KEY}
            options={{
                session_recording: { maskAllInputs: false, maskInputOptions: { password: true } },
                api_host: 'https://d616q0agdgx0y.cloudfront.net',
            }}
        >
            <RegisterGlobalListeners />
            <WorkspaceAwareQueryProvider>
                <TooltipProvider>
                    <Routes />
                </TooltipProvider>
            </WorkspaceAwareQueryProvider>
        </PostHogProvider>
    </React.StrictMode>,
);
