import type { JiraResource } from '@/pages/settings/components/integrations/JiraResourceSelect';
import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import type { IntegrationId } from 'lib/integration';
import type { PostIntegrationSettingsRequest, PostIntegrationSettingsResponse } from 'lib/models/integrations';

export const useIntegrationSettingPost = ({ integrationCreated }: { integrationCreated: boolean }) => {
    const queryClient = useQueryClient();
    return useMutation<PostIntegrationSettingsResponse, DefaultError, PostIntegrationSettingsRequest>({
        url: `/api/v1/integrations/settings?integrationCreated=${integrationCreated}`,
        method: 'POST',
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: ['/api/v1/integrations/status'] });
        },
    });
};

export const useIntegrationResourcePost = (provider: IntegrationId.Jira | IntegrationId.Confluence) => {
    const queryClient = useQueryClient();
    return useMutation<JiraResource, DefaultError>({
        url: `/api/v1/integrations/${provider}/resource`,
        method: 'POST',
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: ['/api/v1/integrations/status'] });
        },
    });
};
