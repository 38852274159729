import * as sentry from '@sentry/react';
import type { MeType } from 'lib/models/me';
import type { Workspace } from 'lib/prisma/types';
import { cloneDeep } from 'lodash-es';
import posthog from 'posthog-js';
import { create } from 'zustand';

export interface Store {
    user: MeType | null;
    workspace: Workspace | null;
    setUser: (user: MeType | null) => void;
    setWorkspace: (workspace: Workspace) => void;
    addWorkspace: (workspace: Workspace) => void;
    removeWorkspace: (workspaceId: string) => void;
    updateWorkspace: (workspace: Workspace) => void;
}

const getActingUser = (user: MeType | null) => {
    if (user?.assumedByUser) {
        return user.assumedByUser;
    } else if (user?.cnslUser) {
        return user.cnslUser;
    }
    return undefined;
};

export const useUser = create<Store>((set, get) => ({
    user: null,
    workspace: null,
    setWorkspace: (workspace: Workspace) => {
        set({ workspace });
    },
    addWorkspace: (workspace: Workspace) => {
        const { user } = get();
        if (user) {
            const newUser = cloneDeep(user);
            newUser.orgMemberships[0].workspaces.push(workspace);
            set({ user: newUser });
        }
    },
    removeWorkspace: (workspaceId: string) => {
        const { user, workspace, setWorkspace } = get();
        if (user) {
            const newUser = cloneDeep(user);
            newUser.orgMemberships[0].workspaces = newUser.orgMemberships[0].workspaces.filter(
                w => w.id !== workspaceId,
            );
            set({ user: newUser });

            // If the deleted workspace was selected, select the first available workspace
            if (workspace?.id === workspaceId && newUser.orgMemberships[0].workspaces.length > 0) {
                setWorkspace(newUser.orgMemberships[0].workspaces[0]);
            }
        }
    },
    updateWorkspace: (updatedWorkspace: Workspace) => {
        const { user, workspace } = get();
        if (user) {
            const newUser = cloneDeep(user);
            newUser.orgMemberships[0].workspaces = newUser.orgMemberships[0].workspaces.map(w =>
                w.id === updatedWorkspace.id ? updatedWorkspace : w,
            );
            set({ user: newUser });

            // If the updated workspace is currently selected, update it in the state
            if (workspace?.id === updatedWorkspace.id) {
                set({ workspace: updatedWorkspace });
            }
        }
    },
    setUser: (user: MeType | null) => {
        set({ user });

        const actingUser = getActingUser(user);
        if (actingUser && user) {
            posthog.identify(user.distinctId, {
                id: actingUser.id,
                email: actingUser.email,
                displayName: actingUser.displayName,
                firstName: actingUser.firstName,
                lastName: actingUser.lastName,
                department: actingUser.department,
                title: actingUser.title,
                orgId: actingUser.orgId,
                createdAt: actingUser.createdAt,
                updatedAt: actingUser.updatedAt,
            });

            sentry.setUser({
                id: user.distinctId,
                email: actingUser.email,
                displayName: actingUser.displayName,
                firstName: actingUser.firstName,
                lastName: actingUser.lastName,
                department: actingUser.department,
                title: actingUser.title,
                orgId: actingUser.orgId,
            });
        } else {
            posthog.reset();
        }
    },
}));

export const getOrgMembership = (user: MeType | null) => {
    return user?.orgMemberships?.[0];
};

export const getDefaultWorkspace = (user: MeType | null) => {
    return user?.orgMemberships?.[0]?.workspaces?.find(w => w.is_default);
};

export const getFirstWorkspace = (user: MeType | null) => {
    const allWorkspaces = user?.orgMemberships?.flatMap(org => org.workspaces) || [];
    return allWorkspaces.length > 0 ? allWorkspaces[0] : null;
};
