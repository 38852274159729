import { useQuery } from '@/lib/hooks/useQuery';
import { useUser } from '@/stores/useUser';
import type { ListCategoryResponse } from 'lib/models/category';
import type { CnslCategory } from 'lib/prisma/types';

export const useCategories = () => {
    const { workspace } = useUser();
    return useQuery<ListCategoryResponse>({
        url: `/api/v1/categories`,
        queryKey: ['categories', workspace?.id ?? ''],
    });
};

export const useFlatCategories = () => {
    const { workspace } = useUser();
    return useQuery<{ categories: CnslCategory[] }>({
        url: `/api/v1/categories/flat`,
        queryKey: ['categories-flat', workspace?.id ?? ''],
    });
};
