import { queryClient } from '@/main';
import type { DefaultError } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '../../lib/hooks/useMutation';

export const useUnbecome = () => {
    const navigate = useNavigate();

    // Have to refresh the page manually because the browser wasn't doing it for us for some reason
    const refreshPage = () => {
        navigate('/');
        window.location.reload();
    };
    return useMutation<void, DefaultError>({
        url: `/api/v1/admin/unbecome`,
        method: 'POST',
        onSuccess: async () => {
            await queryClient.invalidateQueries();
            refreshPage();
        },
        successToast: {
            message: 'You have stepped down from your throne',
        },
    });
};
