import { useMutation } from '@lib/hooks/useMutation';
import { useQuery } from '@lib/hooks/useQuery.tsx';
import type { DefaultError } from '@tanstack/react-query';
import type { OrgchartProviderEnum, OrgchartSettings } from 'lib/models/settings/orgchart';

export const useOrgchartFields = () => {
    return useQuery<OrgchartProviderEnum>({
        url: `/api/v1/settings/orgchart/provider`,
    });
};

export const useOrgChartConfig = () => {
    return useQuery<{ config?: OrgchartSettings }>({
        url: `/api/v1/settings/orgchart/config`,
    });
};

export const useDefaultOrgchartSettingsPut = () => {
    return useMutation<OrgchartSettings, DefaultError, OrgchartSettings>({
        url: `/api/v1/settings/orgchart/provider`,
        method: 'PUT',
        successToast: {
            message: 'Org chart settings updated',
        },
        errorToast: {
            message: 'Problem updating settings',
        },
    });
};
