import { useMutation } from '@lib/hooks/useMutation';
import type { DefaultError } from '@tanstack/react-query';
import type { WorkspaceSetting } from 'lib/prisma/types';

type SupportedUpdateFields = Partial<Pick<WorkspaceSetting, 'ticket_reaction' | 'sla_hours'>>;

export const useUpdateWorkspaceSettings = () => {
    return useMutation<WorkspaceSetting, DefaultError, SupportedUpdateFields>({
        url: `/api/v1/workspace-settings`,
        method: 'PATCH',
        errorToast: {
            message: 'Problem updating settings',
        },
    });
};
