import { useMemo } from 'react';

const getJiraFromTransitionsForOption = (
    transitions: Array<{ to: string; from: string[] }> | undefined,
    options: Array<{ value: string; label: string }> | undefined,
    selectedOption: { value: string } | undefined,
) => {
    const fromStates = transitions
        ?.filter(t => t.to === selectedOption?.value)
        .flatMap(t => t.from)
        .map(f => options?.find(o => o.value === f)?.label);
    return [...new Set(fromStates ?? [])] as string[];
};

export const useJiraStatuses = ({
    resolutions,
    options,
    selectedOption,
    transitions,
}: {
    resolutions?: Array<{ id: string; name: string }>;
    options?: Array<{ value: string; label: string }>;
    selectedOption?: { value: string };
    transitions?: Array<{ to: string; from: string[] }>;
}) => {
    const resolutionTypeOptions = resolutions?.map(r => ({ value: r.id ?? '', label: r.name ?? '' })) ?? [];

    const allJiraFromTransitions = useMemo(
        () => getJiraFromTransitionsForOption(transitions, options, selectedOption),
        [transitions, options, selectedOption],
    );

    return {
        allJiraFromTransitions,
        resolutionTypeOptions,
    };
};
