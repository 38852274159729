import { SlackChannelSelect } from '@/components/SlackChannelSelect';
import { Loader } from '@/components/loader';
import { Label } from '@/components/ui/form';
import { useUpdateErrorEscalationSettings } from '@/hooks/mutations/settings/useErrorEscalationSettings';
import { useErrorEscalationSettings } from '@/hooks/queries/settings/useErrorEscalationSettings';
import { SectionHeader } from '@pages/settings/components/Approvals/Approvals';
import type { EscalateConfigResponseType } from 'lib/models/settings/escalate';
import { TriangleAlert } from 'lucide-react';
import { useState } from 'react';

export const EscalationSettingForm = ({ config }: { config: EscalateConfigResponseType }) => {
    const { mutate: updateSettings } = useUpdateErrorEscalationSettings();
    const [selectedChannel, setSelectedChannel] = useState<{ label: string; value: string } | null>(
        config.slack_channel ? { label: config.slack_channel.name, value: config.slack_channel.id } : null,
    );

    return (
        <div className="flex flex-col gap-xl">
            <section className="border-grey border rounded-md">
                <SectionHeader
                    heading="IT Team Escalation"
                    description="How Console will notify you if something goes wrong or needs input from an IT admin"
                    icon={<TriangleAlert />}
                    iconBg="bg-bg-red-secondary text-body-white"
                />

                <div className="flex flex-col p-lg gap-lg">
                    <div className="flex gap-md">
                        <div className="flex flex-col gap-sm">
                            <Label className="font-medium">Post in Slack channel</Label>
                            <SlackChannelSelect
                                isMulti={false}
                                selected={selectedChannel ?? undefined}
                                onChange={value => {
                                    if (value && 'value' in value) {
                                        setSelectedChannel(value);
                                        updateSettings({
                                            external_slack_channel_id: value.value,
                                        });
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export const EscalationSetting = () => {
    const { data } = useErrorEscalationSettings();
    return <>{data?.config ? <EscalationSettingForm config={data.config} /> : <Loader />}</>;
};
