import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

export function NotFoundPage() {
    const error = useRouteError();
    useEffect(() => {
        if (error) {
            console.error(error);
            Sentry.captureException(error);
        }
    }, [error]);

    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <h1 className="text-base">Oops!</h1>
            <p className="text-body-subtle">Sorry, the page you are looking for does not exist.</p>
        </div>
    );
}
