import { type FlagKey, defaultFlags } from 'lib/flags';
import posthog from 'posthog-js';
import { useFeatureFlagEnabled, useFeatureFlagVariantKey } from 'posthog-js/react';
/**
 * Wrapper around posthog API that uses default config locally. Defaults flags to false if not set or loading.
 */
export function useFeatureFlag(flag: FlagKey): boolean {
    const isEnabled = useFeatureFlagEnabled(flag);
    if (isEnabled === undefined && import.meta.env.MODE === 'development') {
        return defaultFlags[flag] === true;
    }
    return !!isEnabled;
}

/**
 * Helper function to check if a feature flag directly when you're not
 * able to use a hook e.g. in some conditional/iterative logic.
 */
export function isFeatureFlagEnabled(flag: FlagKey): boolean {
    const isEnabled = posthog.isFeatureEnabled(flag);
    if (isEnabled === undefined && import.meta.env.MODE === 'development') {
        return defaultFlags[flag] === true;
    }
    return !!isEnabled;
}

/**
 * Wrapper around posthog API that uses default config locally. Returns undefined if flag is not set or is loading.
 */
export function useFeatureFlagWithLoading(flag: FlagKey): boolean | undefined {
    const isEnabled = useFeatureFlagEnabled(flag);
    if (isEnabled === undefined && import.meta.env.MODE === 'development') {
        return defaultFlags[flag] === true;
    }
    return isEnabled;
}

export function useFeatureFlagVariant(flag: FlagKey): string | boolean | undefined {
    const variantKey = useFeatureFlagVariantKey(flag);
    if (variantKey === undefined && import.meta.env.MODE === 'development') {
        return defaultFlags[flag];
    }
    return variantKey;
}
