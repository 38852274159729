import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import type {
    SchemaAddMembersType,
    SchemaSuspendMemberType,
    SchemaUnsuspendMemberType,
    SchemaUpdateMemberType,
} from 'lib/models/members';

export const listMemberKey = ['members', 'list'];

export const useMemberUpdate = () => {
    const queryClient = useQueryClient();
    return useMutation<{ ok: boolean }, DefaultError, SchemaUpdateMemberType>({
        url: `/api/v1/members`,
        method: 'PUT',
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: listMemberKey, exact: true });
        },
        successToast: {
            message: 'Role updated',
        },
    });
};

export const useMemberAdd = () => {
    const queryClient = useQueryClient();
    return useMutation<{ ok: boolean }, DefaultError, SchemaAddMembersType>({
        url: `/api/v1/members`,
        method: 'POST',
        onSuccess: async (_, variables) => {
            await queryClient.refetchQueries({ queryKey: listMemberKey, exact: false });
            await queryClient.refetchQueries({ queryKey: ['/api/v1/members/invited'], exact: true });

            // If a workspace was specified, also refetch workspace-related queries
            if (variables.workspaceId) {
                await queryClient.refetchQueries({ queryKey: ['members', 'list', variables.workspaceId], exact: true });
            }
        },
        errorToast: {
            message: 'Please enter a valid email address',
        },
        successToast: {
            message: 'User added to your organization',
        },
    });
};

export const useMemberSuspend = () => {
    const queryClient = useQueryClient();
    return useMutation<{ ok: boolean }, DefaultError, SchemaSuspendMemberType>({
        url: `/api/v1/members`,
        method: 'DELETE',
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: listMemberKey, exact: true });
        },
        successToast: {
            message: 'User suspended',
        },
    });
};

export const useMemberReinstate = () => {
    const queryClient = useQueryClient();
    return useMutation<{ ok: boolean }, DefaultError, SchemaUnsuspendMemberType>({
        url: `/api/v1/members/reinstate`,
        method: 'PUT',
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: listMemberKey, exact: true });
        },
        successToast: {
            message: 'User added to your organization',
        },
    });
};
