import { getOrgMembership, useUser } from '@/stores/useUser';
import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import type { Prisma } from 'lib/prisma/client';
import type { Workspace } from 'lib/prisma/types';

export const useWorkspaceCreate = () => {
    const queryClient = useQueryClient();
    const { addWorkspace } = useUser();
    return useMutation<{ workspace: Workspace }, DefaultError, { name: string; description: string }>({
        url: `/api/v1/workspaces`,
        method: 'POST',
        onSuccess: async ({ workspace }) => {
            addWorkspace(workspace);
            await queryClient.refetchQueries({ queryKey: ['/api/v1/workspaces'] });
        },
        successToast: {
            message: 'Workspace created successfully',
        },
        errorToast: {
            message: 'Failed to create workspace',
        },
    });
};

export const useWorkspaceUpdate = (id: string) => {
    const queryClient = useQueryClient();
    const { updateWorkspace } = useUser();
    return useMutation<
        { workspace: Workspace },
        DefaultError,
        { name?: string; identifier?: string; description?: string }
    >({
        url: `/api/v1/workspaces/${id}`,
        method: 'PATCH',
        onSuccess: async ({ workspace }) => {
            updateWorkspace(workspace);
            queryClient.clear();
        },
        successToast: {
            message: 'Workspace updated successfully',
        },
        errorToast: {
            message: 'Failed to update workspace',
        },
    });
};

export const useWorkspaceDelete = (id: string) => {
    const queryClient = useQueryClient();
    const { removeWorkspace } = useUser();
    return useMutation<void, DefaultError, void>({
        url: `/api/v1/workspaces/${id}`,
        method: 'DELETE',
        onSuccess: async () => {
            queryClient.clear();
            removeWorkspace(id);
        },
        successToast: {
            message: 'Workspace deleted successfully',
        },
        errorToast: {
            message: 'Failed to delete workspace',
        },
    });
};

export const useWorkspaceAddMember = (id: string) => {
    const queryClient = useQueryClient();
    const { user, addWorkspace } = useUser();
    return useMutation<
        { workspaceMember: Prisma.workspace_memberGetPayload<{ include: { workspace: true } }> },
        DefaultError,
        { memberId: string }
    >({
        url: `/api/v1/workspaces/${id}/members`,
        method: 'POST',
        onSuccess: async (data, { memberId }) => {
            queryClient.clear();
            const orgMembership = getOrgMembership(user);
            if (orgMembership?.id === memberId) {
                addWorkspace(data.workspaceMember.workspace);
            }
        },
        successToast: {
            message: 'Member added to workspace successfully',
        },
        errorToast: {
            message: 'Failed to add member to workspace',
        },
    });
};

export const useWorkspaceRemoveMember = (id: string, memberId: string) => {
    const queryClient = useQueryClient();
    const { removeWorkspace, user } = useUser();
    return useMutation<void, DefaultError, void>({
        url: `/api/v1/workspaces/${id}/members/${memberId}`,
        method: 'DELETE',
        onSuccess: async () => {
            queryClient.clear();
            const orgMembership = getOrgMembership(user);
            if (orgMembership?.id === memberId) {
                removeWorkspace(id);
            }
        },
        successToast: {
            message: 'Member removed from workspace successfully',
        },
        errorToast: {
            message: 'Failed to remove member from workspace',
        },
    });
};
