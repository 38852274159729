import { isFeatureFlagEnabled } from '@/hooks/useFeatureFlag';
import { cn } from '@lib/styling';
import type { FlagKey } from 'lib/flags/keys';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Badge } from '@/components/ui/badge/badge';
import { getOrgMembership, useUser } from '@/stores/useUser';
import { bookDemoPopup } from '../lib/calendly';

type MenuItem = {
    name: ReactNode;
    pathname: string;
    matchPathExactly?: boolean;
    count?: number;
    flag?: FlagKey;
    disabledForNerfed?: boolean;
    disabledForCurrentWorkspace?: boolean;
    Icon: React.FC<React.SVGProps<SVGSVGElement>>;
};

export type NavMenuItem = MenuItem | { section: string };

const pathMatches = (pathname: string, item: MenuItem, workspaceIdentifier: string) => {
    if (item.matchPathExactly) {
        return pathname === item.pathname;
    }
    return pathname.startsWith(`/${workspaceIdentifier}/${item.pathname}`);
};

export const NavMenu = ({ items }: { items: NavMenuItem[] }) => {
    const { pathname } = useLocation();
    const { user, workspace } = useUser();
    const nerfed = getOrgMembership(user)?.nerfed;

    if (!workspace) {
        return null;
    }

    const availableItems = useMemo(() => {
        return items.filter(item => {
            if ('flag' in item && item.flag) {
                return isFeatureFlagEnabled(item.flag);
            }
            return true;
        });
    }, [items]);

    return (
        <div className="gap-[2px] p-sm flex h-full flex-col overflow-auto font-medium">
            {availableItems
                // Filter out items that are disabled for the current workspace
                .filter(item => !('disabledForCurrentWorkspace' in item && item.disabledForCurrentWorkspace === true))
                .map(item => {
                    if ('section' in item) {
                        return (
                            <div key={item.section} className="text-xs text-body-subtle p-sm first:mt-[2px] mt-lg">
                                {item.section}
                            </div>
                        );
                    }

                    const { Icon } = item;
                    const isActive = pathMatches(pathname, item, workspace.identifier);

                    const className = cn(
                        'px-md py-sm h-7 hover:bg-bg-grey-primary-hover gap-md flex items-center rounded-sm',
                        isActive ? 'bg-bg-grey-primary-hover' : '',
                    );

                    const isDisabled = item.disabledForNerfed && nerfed;
                    const handleDisabledClick = (e: React.MouseEvent) => {
                        e.preventDefault();
                        bookDemoPopup();
                    };

                    const href = `/${workspace.identifier}/${item.pathname}`;

                    return (
                        <Link
                            className={cn(className, isDisabled && 'opacity-50')}
                            key={`${item.name}`}
                            to={href}
                            onClick={isDisabled ? handleDisabledClick : undefined}
                        >
                            <Icon className="size-lg" />
                            <div className="flex w-full items-baseline justify-between">
                                <div>{item.name}</div>
                                {item.count !== undefined && (
                                    <Badge className={cn('min-w-[26px]', isActive && 'border-steel-800')}>
                                        {item.count}
                                    </Badge>
                                )}
                            </div>
                        </Link>
                    );
                })}
        </div>
    );
};
