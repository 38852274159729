import { Building, MessageSquareText, Settings as SettingsIcon, Users } from 'lucide-react';
import { Link, useParams } from 'react-router-dom';

import { Brow } from '@/components/Brow';

import { Button } from '@/components/ui/button/button';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { bookDemoPopup } from '@/lib/calendly';
import { cn } from '@/lib/styling';
import { SETTINGS } from '@/routes/paths';
import { getOrgMembership, useUser } from '@/stores/useUser';
import { FlagKey } from 'lib/flags/keys';
import { CnslRole } from 'lib/prisma/enums';
import { HelpDeskRoot } from './HelpDesk/HelpDeskRoot';
import { Approvals } from './components/Approvals/Approvals';
import Categories from './components/Categories';
import General from './components/General';
import { Members, WorkspaceMembers } from './components/Members';
import SettingsContent from './components/SettingsContent';
import SettingsNav from './components/SettingsNav';
import { WorkspaceSettings } from './components/Workspaces/settings';
import OrgIntegrations from './components/integrations/Organization';
import type { Setting } from './types/Settings';
import { findGroup, findSettingItem, formatPathname } from './utils';

const Settings = () => {
    const isWorkspacesEnabled = useFeatureFlag(FlagKey.Workspaces);
    const { user, workspace } = useUser();
    const orgMembership = getOrgMembership(user);
    const isDefaultWorkspaceUser = !!orgMembership?.workspaces.some(w => w.is_default);
    const isAdmin = orgMembership?.role === CnslRole.ADMIN;
    const { workspaceIdentifier } = useParams();

    const settings = [
        {
            label: 'Organization',
            icon: Building,
            pathname: 'organization',
            children: [
                {
                    label: 'General',
                    index: true,
                    pathname: 'general',
                    Component: General,
                    description: 'Configure Console for your organization',
                },
                {
                    label: 'Approvals',
                    hide: !isDefaultWorkspaceUser,
                    pathname: 'approvals',
                    description: 'Configure default approval settings',
                    Component: Approvals,
                    disabledIfNerfed: true,
                    onDisabledClick: () => {
                        bookDemoPopup();
                    },
                },
                {
                    label: isWorkspacesEnabled ? 'Workspaces' : 'Members',
                    hide: !isAdmin,
                    pathname: isWorkspacesEnabled ? 'workspaces' : 'members',
                    description: `View and manage the ${isWorkspacesEnabled ? 'workspaces in' : 'members of'} your organization`,
                    Component: Members,
                },
                {
                    label: 'Integrations',
                    hide: !isDefaultWorkspaceUser,
                    pathname: 'integrations',
                    description: 'Connect your service accounts to see your data in Console',
                    Component: OrgIntegrations,
                },
            ],
        },
        {
            label: `Workspace${workspace?.name ? ` (${workspace?.name})` : ''}`,
            hide: !isWorkspacesEnabled,
            icon: Users,
            pathname: 'workspace',
            children: [
                {
                    label: 'General',
                    index: true,
                    pathname: 'general',
                    description: 'Configure your workspace',
                    Component: WorkspaceSettings,
                },
                {
                    label: 'Members',
                    pathname: 'members',
                    description: `View and manage the members of the ${workspace?.name} workspace`,
                    Component: WorkspaceMembers,
                },
                {
                    label: 'Help Desk Configuration',
                    pathname: 'help-desk-configuration',
                    LabelAction: () => {
                        return isDefaultWorkspaceUser ? (
                            <Link to={`/${workspaceIdentifier}/settings/organization/integrations`}>
                                <Button mode="borderless">
                                    <SettingsIcon className="size-5" />
                                </Button>
                            </Link>
                        ) : null;
                    },
                    description: 'Configure fields for tickets created by Console',
                    Component: HelpDeskRoot,
                },
                // TODO:  Disabled until we implement workspace scoped categories - CNSL-2145
                //
                {
                    label: 'Categories',
                    pathname: 'categories',
                    description: 'Configure categories for your requests',
                    Component: Categories,
                    hide: !isDefaultWorkspaceUser,
                    disabledIfNerfed: true,
                },
            ],
        },
        {
            label: 'Requests',
            pathname: 'requests',
            hide: isWorkspacesEnabled,
            icon: MessageSquareText,
            children: [
                {
                    label: 'Help Desk Configuration',
                    pathname: 'help-desk-configuration',
                    LabelAction: () => (
                        <Link to="/settings/organization/integrations">
                            <Button mode="borderless">
                                <SettingsIcon className="size-5" />
                            </Button>
                        </Link>
                    ),
                    description: 'Configure fields for tickets created by Console',
                    Component: HelpDeskRoot,
                },
                {
                    label: 'Categories',
                    pathname: 'categories',
                    description: 'Configure categories for your requests',
                    Component: Categories,
                },
                {
                    label: 'Types',
                    hide: true,
                },
            ],
        },
    ] satisfies Setting[];

    const { group, setting, subsetting } = useParams();

    const selectedGroup = findGroup(settings, group);
    const selectedSetting = findSettingItem(selectedGroup?.children, setting);
    const browData = selectedGroup
        ? [
              {
                  label: selectedGroup.label,
                  pathname: selectedGroup.pathname,
                  href: formatPathname(`/${workspaceIdentifier}/${SETTINGS}`, selectedGroup.pathname || ''),
              },
              {
                  label: selectedSetting?.label,
                  href: formatPathname(
                      `/${workspaceIdentifier}/${SETTINGS}`,
                      selectedGroup.pathname || '',
                      selectedSetting?.index ? undefined : selectedSetting?.pathname || '',
                  ),
                  subsetting,
              },
              ...(subsetting
                  ? [
                        {
                            label: subsetting,
                            href: formatPathname(
                                `/${workspaceIdentifier}/${SETTINGS}`,
                                selectedGroup.pathname || '',
                                selectedSetting?.pathname || '',
                                subsetting,
                            ),
                        },
                    ]
                  : []),
          ]
        : [];

    const activeRoute = `/${group}${setting ? `/${setting}` : ''}`;

    return (
        <div className={cn('flex size-full flex-col')}>
            <Brow data={browData} />
            <div className="flex grow overflow-auto">
                <div className="bg-bg-overlay border-subtle-hover sticky top-0 w-[300px] shrink-0 overflow-auto border-r-[0.5px]">
                    <div className="p-lg bg-bg-overlay sticky top-0 z-10 flex items-center">
                        <h2 className="text-base">Settings</h2>
                    </div>
                    <SettingsNav
                        basePath={`/${workspaceIdentifier}/${SETTINGS}`}
                        settings={settings}
                        activeRoute={activeRoute}
                    />
                </div>
                <div className="grow px-2xl">
                    <SettingsContent settings={settings} />
                </div>
            </div>
        </div>
    );
};

export default Settings;
